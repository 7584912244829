import axios from 'axios'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails, updateUserProfile } from 'state/actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from 'state/constants/userConstants'
import Alerts from 'common/components/Alerts'
import Loader from 'common/components/Loader'
import Notifications from 'common/components/Notifications'

const ProfileSettingsForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)
  const [isShowing, setIsShowing] = useState(false)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    if (!user || !user.name) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET })
      dispatch(getUserDetails('profile'))
    } else {
      setName(user.name)
      setEmail(user.email)
      setPhone(user.phone)
      setImage(user.image)
    }
  }, [dispatch, user, success])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Hasło nie pasuje')
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          email,
          phone,
          password,
          image,
        })
      )
    }
  }

  return (
    <>
      <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Profil użytkownika
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Podstawowe dane służące do kontaktu i przesyłania powiadomień
            </p>
          </div>
          <div className='mt-5 md:mt-0 md:col-span-2'>
            {message && <Alerts error>{message}</Alerts>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Alerts attention>Błąd!</Alerts>
            ) : (
              <form onSubmit={submitHandler}>
                <div className='grid grid-cols-6 gap-6'>
                  <div className='col-span-6 sm:col-span-4'>
                    <label
                      htmlFor='name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Imię i nazwisko
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='name'
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                        className='focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md'
                      />
                    </div>
                  </div>

                  <div className='col-span-6 sm:col-span-3'>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Adres email
                    </label>
                    <input
                      type='text'
                      name='email'
                      value={email || ''}
                      onChange={(e) => setEmail(e.target.value)}
                      className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                    />
                  </div>

                  <div className='col-span-6 sm:col-span-3'>
                    <label
                      htmlFor='phone'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Telefon
                    </label>
                    <input
                      type='text'
                      name='phone'
                      value={phone || ''}
                      onChange={(e) => setPhone(e.target.value)}
                      className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                    />
                  </div>

                  <div className='col-span-6 sm:col-span-3'>
                    <label
                      htmlFor='password'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Nowe hasło
                    </label>
                    <input
                      type='password'
                      placeholder='Wprowadź hasło'
                      name='password'
                      value={password || ''}
                      onChange={(e) => setPassword(e.target.value)}
                      className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-gray-500'
                    />
                  </div>

                  <div className='col-span-6 sm:col-span-3'>
                    <label
                      htmlFor='confirmPassword'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Potwierdź hasło
                    </label>
                    <input
                      type='password'
                      name='confirmPassword'
                      value={confirmPassword || ''}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                    />
                  </div>

                  <div className='col-span-6 sm:col-span-6'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Zdjęcie
                    </label>
                    <div className='mt-1 flex items-center space-x-5'>
                      <img
                        className='h-12 w-12 rounded-full'
                        src={image}
                        alt=''
                      />
                      <label
                        htmlFor='image'
                        className='bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      >
                        <span>Zmień</span>
                        <input
                          name='image'
                          type='file'
                          className='sr-only'
                          onChange={uploadFileHandler}
                        />
                        {uploading && <Loader />}
                      </label>
                    </div>
                  </div>

                  <div className='col-span-6 sm:col-span-6'>
                    <div className='flex justify-end'>
                      <button
                        type='button'
                        className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      >
                        Anuluj
                      </button>
                      <button
                        type='submit'
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      >
                        Zaktualizuj
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      {isShowing && (
        <Notifications
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          title={'Zapisano!'}
          content={'Twoje dane zostały zmienione!'}
        />
      )}
    </>
  )
}

export default ProfileSettingsForm
