import axios from 'axios'
import { Fragment, useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'

export default function ReportServiceForm({
  open,
  setOpen,
  setIsShowing,
  defaultMachine,
  machines,
}) {
  const cancelButtonRef = useRef(null)

  const [email, setEmail] = useState('')
  const [machine, setMachine] = useState(
    defaultMachine?.serialNumber || machines[0].serialNumber
  )
  const [message, setMessage] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    setEmail(userInfo.email)
  }, [setEmail, userInfo.email])

  const sendEmail = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.post(
        'https://myimago.pl/api/email/service',
        {
          email,
          machine,
          message,
        },
        config
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleSend = async (e) => {
    e.preventDefault()
    sendEmail()
    setOpen(false)
    setIsShowing(true)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <form className='space-y-8' onSubmit={handleSend}>
                <div>
                  <div>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      Zgłoszenie sprawy serwisowej
                    </Dialog.Title>
                  </div>

                  <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                    <div className='sm:col-span-6'>
                      <label
                        htmlFor='machine'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Wybierz maszynę
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <select
                          name='machine'
                          onChange={(e) => setMachine(e.target.value)}
                          value={machine}
                          className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                        >
                          {machines.map((machine, i) => (
                            <option value={machine.serialNumber} key={i}>
                              {machine.name} ({machine.serialNumber})
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className='sm:col-span-6'>
                      <label
                        htmlFor='text'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Opis zgłoszenia
                      </label>
                      <div className='mt-1'>
                        <textarea
                          id='text'
                          name='text'
                          rows={6}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          className='shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border border-gray-300 rounded-md'
                        />
                      </div>
                      <p className='mt-2 text-sm text-gray-500'>
                        Napisz w kilku zdaniach o problemie starając się jak
                        najlepiej opisać przyczynę zgłoszenia.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='pt-5'>
                  <div className='flex justify-end'>
                    <button
                      onClick={() => setOpen(false)}
                      type='button'
                      className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Anuluj
                    </button>
                    <button
                      type='submit'
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Wyślij
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
