import axios from 'axios'
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { createProduct } from 'state/actions/productActions'
import { PRODUCT_CREATE_RESET } from 'state/constants/productConstants'
import Loader from 'common/components/Loader'

const options = [
  { value: 'atramenty VULPES', label: 'atramenty VULPES' },
  { value: 'atramenty FALCO', label: 'atramenty FALCO' },
  { value: 'części zamienne falco', label: 'części zamienne falco' },
  {
    value: 'akcesoria i środki czyszczące',
    label: 'akcesoria i środki czyszczące',
  },
  { value: 'części zamienne vulpes', label: 'części zamienne vulpes' },
  { value: 'części zamienne vulpes', label: 'części zamienne vulpes' },
  { value: 'akcesoria serwisowe', label: 'akcesoria serwisowe' },
  { value: 'foremki i wykrojniki', label: 'foremki i wykrojniki' },
  { value: 'matryce do druku', label: 'matryce do druku' },
  { value: 'narzędzia cukiernicze', label: 'narzędzia cukiernicze' },
]

const AddMachineForm = ({ open, setOpen }) => {
  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [price, setPrice] = useState(0)
  const [capacity, setCapacity] = useState(1)
  const [quantity, setQuantity] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [subcategory, setSubcategory] = useState([])
  //const [files, setFiles] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productCreate = useSelector((state) => state.productCreate)
  const { success: successCreate } = productCreate

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })

    if (successCreate) {
      setOpen(false)
    }
  }, [dispatch, successCreate, setOpen])

  const handleSelect = (e) => {
    const updatedOptions = [...e.target.options]
      .filter((option) => option.selected)
      .map((x) => x.value)
    setSubcategory(updatedOptions)
  }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const createProductHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProduct({
        name,
        image,
        price,
        capacity,
        quantity,
        description,
        category,
        subcategory,
      })
    )
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-50 inset-0 overflow-y-auto'
        //initialFocus={cancelButtonRef}
        open={false}
        onClose={setOpen}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <form className='space-y-6' onSubmit={createProductHandler}>
                <div>
                  <div>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      Dodaj nową maszynę
                    </Dialog.Title>
                    <Dialog.Description className='mt-3 text-sm text-gray-500'>
                      Formularz umożliwiający dodawanie nowych maszyn.
                    </Dialog.Description>
                  </div>

                  <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                    <div className='sm:col-span-6'>
                      <label
                        htmlFor='name'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Nazwa
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='name'
                          id='name'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-6'>
                      <label className='block text-sm font-medium text-gray-700'>
                        Zdjęcie
                      </label>
                      <div className='mt-1 flex items-center space-x-5'>
                        <img
                          className='h-12 w-12 rounded-full'
                          src={image}
                          alt=''
                        />
                        <label
                          htmlFor='image-file'
                          className='bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                        >
                          <span>Zmień</span>
                          <input
                            id='image-file'
                            name='file-upload'
                            type='file'
                            className='sr-only'
                            onChange={uploadFileHandler}
                          />
                        </label>
                      </div>
                    </div>
                    {uploading && <Loader />}

                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='price'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Cena
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='price'
                          id='price'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='capacity'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Pojemność
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='capacity'
                          id='capacity'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={capacity}
                          onChange={(e) => setCapacity(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='quantity'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Ilość
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='quantity'
                          id='quantity'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-6'>
                      <label
                        htmlFor='description'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Opis
                      </label>
                      <div className='mt-1'>
                        <textarea
                          id='description'
                          name='description'
                          rows={3}
                          className='shadow-sm focus:ring-yellow-500 focus:border-yellow-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-3'>
                      <label
                        htmlFor='category'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Kategoria
                      </label>
                      <select
                        id='category'
                        name='category'
                        className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option>atramenty</option>
                        <option>zaopatrzenie</option>
                        <option>akcesoria cukiernicze</option>
                      </select>
                    </div>

                    <div className='sm:col-span-3'>
                      <label
                        htmlFor='subcategory'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Podkategoria
                      </label>
                      <select
                        id='subcategory'
                        name='subcategory'
                        className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                        multiple={true}
                        onChange={handleSelect}
                        value={subcategory}
                        options={options}
                      >
                        {options.map((item, i) => {
                          return (
                            <option key={i} value={item.value}>
                              {item.label}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='pt-5'>
                  <div className='flex justify-end'>
                    <button
                      onClick={() => setOpen(false)}
                      type='button'
                      className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Anuluj
                    </button>
                    <button
                      type='submit'
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Wyślij
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddMachineForm
