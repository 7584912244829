import Heading from 'common/components/Heading'
import ProfileSettingsForm from 'pages/SettingsPage/components/ProfileSettingsForm'
import BillingSettingsForm from 'pages/SettingsPage/components/BillingSettingsForm'

function Settings() {
  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Ustawienia</Heading>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-6 space-y-6'>
            <ProfileSettingsForm />
            <BillingSettingsForm />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Settings
