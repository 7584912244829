import axios from 'axios'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Alerts from 'common/components/Alerts'

function LoginScreen() {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)
  const [success, setSuccess] = useState(false)

  const { token } = useParams()

  console.log(token)

  const submitHandler = async (e) => {
    e.preventDefault()

    if (newPassword !== confirmPassword) {
      return setMessage('Hasło nie pasuje')
    } else {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

        await axios.post(
          'http://127.0.0.1:5000/api/users/new-password',
          {
            sentToken: token,
            newPassword,
          },
          config
        )
        setSuccess(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img
          className='mx-auto h-12 w-auto'
          src='/images/Logo_MyIMAGO.png'
          alt='Workflow'
        />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Ustaw nowe hasło
        </h2>
        <p className='mt-2 text-center text-sm text-gray-600'>
          Wypełnij poniższe pola wpisując nowe hasło.
        </p>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <form onSubmit={submitHandler} className='space-y-6'>
            {message && <Alerts error>{message}</Alerts>}
            {success && (
              <Alerts title='Hasło zostało zmienione!' check>
                Możesz się teraz zalogować do swojego konta używając nowego
                hasła.
              </Alerts>
            )}
            <div>
              <label
                htmlFor='password'
                className='block text-sm font-medium text-gray-700'
              >
                Nowe hasło
              </label>
              <div className='mt-1'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  required
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='password'
                className='block text-sm font-medium text-gray-700'
              >
                Powtórz hasło
              </label>
              <div className='mt-1'>
                <input
                  id='confirm-password'
                  name='confirm-password'
                  type='password'
                  required
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                Ustaw
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginScreen
