import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CheckoutSteps from 'common/components/CheckoutSteps'
import { savePaymentMethod } from 'state/actions/cartActions'

const PaymentScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  // Przekierowanie jeśli nie ma zapisanego adresu wysyłki
  if (!shippingAddress) {
    history.push('/wysylka')
  }

  const [paymentMethod, setPaymentMethod] = useState('Przelew na konto')

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    history.push('/zamowienie')
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <CheckoutSteps step1 step2 step3 />
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-6 space-y-6'>
            <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
              <div className='md:grid md:grid-cols-3 md:gap-6'>
                <div className='md:col-span-1'>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Płatność
                  </h3>
                  <p className='mt-1 text-sm text-gray-500'>
                    Wybierz metodę jaką chcesz opłacić zamównienie
                  </p>
                </div>
                <div className='mt-5 md:mt-0 md:col-span-2'>
                  <form onSubmit={submitHandler}>
                    <div className='mt-4 space-y-4'>
                      <div className='flex items-center'>
                        <input
                          name='paymentMethod'
                          type='radio'
                          value='Pobranie'
                          required
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          className='focus:ring-yellow-500 h-4 w-4 text-yellow-600 border-gray-300'
                        />
                        <label
                          htmlFor='Pobranie'
                          className='ml-3 block text-sm font-medium text-gray-700'
                        >
                          Pobranie
                        </label>
                      </div>
                      <div className='flex items-center'>
                        <input
                          name='paymentMethod'
                          type='radio'
                          value='Przelew na konto'
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          className='focus:ring-yellow-500 h-4 w-4 text-yellow-600 border-gray-300'
                        />
                        <label
                          htmlFor='Przelew na konto'
                          className='ml-3 block text-sm font-medium text-gray-700'
                        >
                          Przelew na konto
                        </label>
                      </div>
                    </div>
                    <div className='mt-6 flex justify-end'>
                      <button
                        type='button'
                        className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      >
                        Anuluj
                      </button>
                      <button
                        type='submit'
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      >
                        Dalej
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PaymentScreen
