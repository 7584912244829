import { useState } from 'react'
import { ShoppingCartIcon } from '@heroicons/react/outline'
import { useDispatch } from 'react-redux'
import { addToCart } from 'state/actions/cartActions.js'

const Product = ({ name, _id, image, title, price, discount }) => {
  const [buttonText, setButtonText] = useState('Do koszyka')

  const dispatch = useDispatch()

  return (
    <li
      key={name}
      className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'
    >
      <a href={`/produkt/${_id}`}>
        <img className='rounded-t-lg' src={image} alt={title} />
      </a>
      <div className='flex-1 flex flex-col justify-between p-4'>
        <h3 className='text-gray-800 text-sm font-medium line-clamp-2'>
          {name}
        </h3>
        {discount > 0 && (
          <p className='mt-2 text-gray-500 text-sm line-through'>{price} zł</p>
        )}
        <p className='mt-2 text-gray-500 text-sm'>{price - discount} zł</p>
        <div className='flex flex-row justify-center mt-4'>
          <button
            type='button'
            onClick={() => {
              dispatch(addToCart(_id, 1))
              setButtonText('Dodano!')
            }}
            className='mt-2 md:mt-0 inline-flex justify-center items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
          >
            <ShoppingCartIcon
              className='ml-0.5 mr-2 h-4 w-4'
              aria-hidden='true'
            />
            {buttonText}
          </button>
        </div>
      </div>
    </li>
  )
}

export default Product
