import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SortAscendingIcon,
  ChevronDownIcon,
  SearchIcon,
} from '@heroicons/react/solid'
import Heading from 'common/components/Heading'
import Alerts from 'common/components/Alerts'
import Loader from 'common/components/Loader'
import StackedList from 'common/components/StackedList'
import AddProductForm from 'common/components/AddProductForm'
import Pagination from 'common/components/Pagination'
import { listProducts, deleteProduct } from 'state/actions/productActions'

const AllProductsScreen = ({ match }) => {
  const [keyword, setKeyword] = useState('')
  const [open, setOpen] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)

  const dispatch = useDispatch()

  let url = match.url

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  const productDelete = useSelector((state) => state.productDelete)
  const { loading: loadingDelete, success: successDelete } = productDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listProducts(keyword, pageNumber))
    }
  }, [dispatch, keyword, userInfo, pageNumber, successDelete])

  const deleteHandler = (id) => {
    dispatch(deleteProduct(id))
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Produkty</Heading>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-2 pb-5 sm:flex sm:items-center sm:justify-between'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Lista wszystkich produktów
            </h3>
            <button
              onClick={() => setOpen(true)}
              className='ml-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
            >
              Dodaj produkt
            </button>
            <div className='mt-3 sm:mt-0 sm:ml-4'>
              <label htmlFor='search-product' className='sr-only'>
                Szukaj produktu
              </label>
              <div className='flex rounded-md shadow-sm'>
                <div className='relative flex-grow focus-within:z-10'>
                  <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                    <SearchIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                  <input
                    type='text'
                    name='search-product'
                    id='search-product'
                    className='focus:ring-yellow-500 focus:border-yellow-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300'
                    placeholder='Szukaj'
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <input
                    type='text'
                    name='search-product'
                    id='search-product'
                    className='p-2.5 hidden focus:ring-yellow-500 focus:border-yellow-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300'
                    placeholder='Szukaj produktu'
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
                <button
                  type='button'
                  className='-ml-px relative inline-flex items-center px-4 py-2 border border-imago_yellow text-sm font-medium rounded-r-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500'
                >
                  <SortAscendingIcon
                    className='h-5 w-5 text-white'
                    aria-hidden='true'
                  />
                  <span className='ml-2'>Sortuj</span>
                  <ChevronDownIcon
                    className='ml-2.5 -mr-1.5 h-5 w-5 text-white'
                    aria-hidden='true'
                  />
                </button>
              </div>
            </div>
          </div>

          {loadingDelete && <Loader />}
          {loading ? (
            <Loader />
          ) : error ? (
            <Alerts>{error}</Alerts>
          ) : (
            <StackedList
              data={products}
              isAdmin={true}
              url={url}
              deleteHandler={deleteHandler}
              loadingDelete={loadingDelete}
            />
          )}
          <Pagination
            pages={pages}
            page={page}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            keyword={keyword ? keyword : ''}
          />
        </div>
      </div>
      {open && <AddProductForm open={open} setOpen={setOpen} />}
    </main>
  )
}

export default AllProductsScreen
