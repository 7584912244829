import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails, updateUserProfile } from 'state/actions/userActions'
import Notifications from 'common/components/Notifications'
import { USER_UPDATE_PROFILE_RESET } from 'state/constants/userConstants'

const BillingSettingsForm = () => {
  const [company, setCompany] = useState('')
  const [taxNumber, setTaxNumber] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [isShowing, setIsShowing] = useState(false)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    if (success) setIsShowing(true)
    if (!user || !user.name) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET })
      dispatch(getUserDetails('profile'))
    } else {
      setCompany(user.company)
      setTaxNumber(user.taxNumber)
      setStreet(user.street)
      setCity(user.city)
      setPostalCode(user.postalCode)
      setCountry(user.country)
    }
  }, [dispatch, user, success])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateUserProfile({
        id: user._id,
        company,
        taxNumber,
        street,
        city,
        postalCode,
        country,
      })
    )
  }

  return (
    <>
      <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Dane rozliczeniowe
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Wszystkie dane, na które ma zostać wystawiona faktura
            </p>
          </div>
          <div className='mt-5 md:mt-0 md:col-span-2'>
            <form onSubmit={submitHandler}>
              <div className='grid grid-cols-6 gap-6'>
                <div className='col-span-6 sm:col-span-4'>
                  <label
                    htmlFor='company'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Nazwa firmy
                  </label>
                  <input
                    type='text'
                    name='company'
                    value={company || ''}
                    onChange={(e) => setCompany(e.target.value)}
                    className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='taxNumber'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Numer NIP
                  </label>
                  <input
                    type='text'
                    name='taxNumber'
                    value={taxNumber || ''}
                    onChange={(e) => setTaxNumber(e.target.value)}
                    className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                </div>

                <div className='col-span-6'>
                  <label
                    htmlFor='street'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Ulica
                  </label>
                  <input
                    type='text'
                    name='street'
                    value={street || ''}
                    onChange={(e) => setStreet(e.target.value)}
                    className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                </div>

                <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                  <label
                    htmlFor='city'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Miasto
                  </label>
                  <input
                    type='text'
                    name='city'
                    value={city || ''}
                    onChange={(e) => setCity(e.target.value)}
                    className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                </div>

                <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                  <label
                    htmlFor='postalCode'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Kod pocztowy
                  </label>
                  <input
                    type='text'
                    name='postalCode'
                    value={postalCode || ''}
                    onChange={(e) => setPostalCode(e.target.value)}
                    className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                </div>

                <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Kraj
                  </label>
                  <input
                    type='text'
                    name='country'
                    value={country || ''}
                    onChange={(e) => setCountry(e.target.value)}
                    className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                </div>

                <div className='col-span-6 sm:col-span-6'>
                  <div className='flex justify-end'>
                    <button
                      type='button'
                      className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Anuluj
                    </button>
                    <button
                      type='submit'
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Zaktualizuj
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {success && (
        <Notifications
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          title={'Zapisano!'}
          content={'Twoje dane zostały zmienione!'}
        />
      )}
    </>
  )
}

export default BillingSettingsForm
