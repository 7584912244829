import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CheckIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid'
import Loader from 'common/components/Loader'
import Alerts from 'common/components/Alerts'
import { Link } from 'react-router-dom'
import { getUserDetails } from 'state/actions/userActions'
import { listMyOrders } from 'state/actions/orderActions'

const OrderStatus = () => {
  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders } = orderList

  useEffect(() => {
    if (!user || !user.name) {
      dispatch(getUserDetails('profile'))
      dispatch(listMyOrders())
    }
  }, [dispatch, user])

  return (
    <section
      aria-labelledby='timeline-title'
      className='lg:col-start-3 lg:col-span-1'
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <Alerts>{error}</Alerts>
      ) : (
        <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6'>
          <h2 id='timeline-title' className='text-lg font-medium text-gray-900'>
            Ostatnie zamówienie
          </h2>
          {orders?.length > 0 ? (
            <>
              <div className='mt-6 flow-root'>
                <ul className='-mb-8'>
                  <li>
                    <div className='relative pb-8'>
                      <span
                        className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
                        aria-hidden='true'
                      ></span>
                      <div className='relative flex space-x-3'>
                        <div>
                          <span className='bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'>
                            <InformationCircleIcon
                              className='h-5 w-5 text-white'
                              aria-hidden='true'
                            />
                          </span>
                        </div>
                        <div className='min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'>
                          <div>
                            <p className='text-sm text-gray-500'>
                              Zamówienie zostało przyjęte do realizacji
                              {/* <span className='font-medium text-gray-900'>
                                3 dni
                              </span> */}
                            </p>
                          </div>
                          <div className='text-right text-sm font-medium whitespace-nowrap text-gray-900'>
                            <time
                              dateTime={new Date(
                                orders[0].orderDate
                              ).toLocaleDateString('pl-PL')}
                            >
                              {new Date(orders[0].orderDate).toLocaleDateString(
                                'pl-PL'
                              )}
                            </time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className='relative pb-8'>
                      <span
                        className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
                        aria-hidden='true'
                      ></span>
                      <div className='relative flex space-x-3'>
                        <div>
                          {orders[0].isPaid ? (
                            <span className='bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'>
                              <CheckIcon
                                className='h-5 w-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          ) : (
                            <span className='bg-red-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'>
                              <XCircleIcon
                                className='h-5 w-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </div>
                        <div className='min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'>
                          <div>
                            <p className='text-sm text-gray-500'>
                              Status płatności:{' '}
                              <span className='font-medium text-gray-900'>
                                {orders[0].isPaid ? 'Opłacone' : 'Nieopłacone'}
                              </span>
                            </p>
                          </div>
                          {orders[0].isPaid ? (
                            <div className='text-right text-sm font-medium whitespace-nowrap text-gray-900'>
                              <time
                                dateTime={new Date(
                                  orders[0].paidAt
                                ).toLocaleDateString('pl-PL')}
                              >
                                {new Date(orders[0].paidAt).toLocaleDateString(
                                  'pl-PL'
                                )}
                              </time>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className='relative pb-8'>
                      <div className='relative flex space-x-3'>
                        <div>
                          {orders[0].isDelivered ? (
                            <span className='bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'>
                              <CheckIcon
                                className='h-5 w-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          ) : (
                            <span className='bg-red-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'>
                              <XCircleIcon
                                className='h-5 w-5 text-white'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </div>
                        <div className='min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'>
                          <div>
                            <p className='text-sm text-gray-500'>
                              Status wysyłki:{' '}
                              <span className='font-medium text-gray-900'>
                                {orders[0].isDelivered
                                  ? 'Wysłane!'
                                  : 'Niewysłane'}
                              </span>
                            </p>
                          </div>
                          {orders[0].isDelivered ? (
                            <div className='text-right text-sm font-medium whitespace-nowrap text-gray-900'>
                              <time
                                dateTime={new Date(
                                  orders[0].isDelivered
                                ).toLocaleDateString('pl-PL')}
                              >
                                {new Date(
                                  orders[0].isDelivered
                                ).toLocaleDateString('pl-PL')}
                              </time>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='mt-6 flex flex-col justify-stretch'>
                <Link
                  to={`/zamowienie/${orders[0]._id}`}
                  className='inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                >
                  Sprawdź szczegóły
                </Link>
              </div>
            </>
          ) : (
            <p className='text-sm text-gray-500'>jeszcze nic nie zamówiłeś!</p>
          )}
        </div>
      )}
    </section>
  )
}

export default OrderStatus
