import { useRef } from 'react'
import { Transition } from '@headlessui/react'
import { ArrowRightIcon } from '@heroicons/react/outline'
import { Stream } from '@cloudflare/stream-react'
import ClipLoader from 'react-spinners/ClipLoader'

const DetailsSidebar = ({ currentFile, isShowing, setIsShowing }) => {
  const videoRef = useRef(null)

  const playVideo = () => {
    videoRef.current.play()
  }

  return (
    <Transition
      show={isShowing}
      enter='transition-opacity duration-75'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-150'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <aside className='hidden w-96 bg-white p-8 border-l border-gray-200 overflow-y-auto lg:block'>
        <div className='pb-16 space-y-6'>
          <div>
            <button
              type='button'
              className='ml-auto block bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500'
              onClick={() => setIsShowing(false)}
            >
              <ArrowRightIcon className=' h-6 w-6' aria-hidden='true' />
            </button>
            <div
              style={{ paddingTop: '56.338%', marginTop: '15px' }}
              className='relative'
            >
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                <ClipLoader color='#e27300' size='60' />
              </div>
              <div className='absolute top-0 left-0 w-full h-full'>
                <Stream
                  src={currentFile.videoID}
                  streamRef={videoRef}
                  controls
                  preload='auto'
                />
              </div>
            </div>
            <div className='mt-4 flex items-start justify-between'>
              <div>
                <h2 className='text-lg font-medium text-gray-900'>
                  <span className='sr-only'>Szczegóły dla</span>
                  {currentFile.name}
                </h2>
                <p className='text-sm font-medium text-gray-500'>??</p>
              </div>
            </div>
          </div>
          <div>
            <h3 className='font-medium text-gray-900'>Informacje</h3>
            <dl className='mt-2 border-t border-b border-gray-200 divide-y divide-gray-200'>
              <div className='py-3 flex justify-between text-sm font-medium'>
                <dt className='text-gray-500'>Długość</dt>
                <dd className='text-gray-900'>{currentFile.length}</dd>
              </div>
              <div className='py-3 flex justify-between text-sm font-medium'>
                <dt className='text-gray-500'>Kategoria</dt>
                <dd className='text-gray-900'>{currentFile.category}</dd>
              </div>
            </dl>
          </div>

          <div className='flex'>
            <button
              onClick={playVideo}
              type='button'
              className='flex-1 bg-yellow-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
            >
              Oglądaj
            </button>
          </div>
        </div>
      </aside>
    </Transition>
  )
}

export default DetailsSidebar
