import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CheckoutSteps from 'common/components/CheckoutSteps'
import { saveShippingAddress } from 'state/actions/cartActions'

const ShippingScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const [address, setAddress] = useState(shippingAddress.address)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(saveShippingAddress({ address, city, postalCode, country }))
    history.push('/platnosc')
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <CheckoutSteps step1 step2 />
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-6 space-y-6'>
            <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
              <div className='md:grid md:grid-cols-3 md:gap-6'>
                <div className='md:col-span-1'>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Dane do wysyłki
                  </h3>
                  <p className='mt-1 text-sm text-gray-500'>
                    Proszę o wypełnienie wszystkich niezbędnych danych, abyśmy
                    mogli przygotować dla Ciebie paczkę do wysyłki.
                  </p>
                </div>
                <div className='mt-5 md:mt-0 md:col-span-2'>
                  <form onSubmit={submitHandler}>
                    <div className='grid grid-cols-6 gap-6'>
                      <div className='sm:col-span-6'>
                        <label
                          htmlFor='street_address'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Adres
                        </label>
                        <div className='mt-1'>
                          <input
                            type='text'
                            name='address'
                            id='address'
                            value={address || ''}
                            required
                            onChange={(e) => setAddress(e.target.value)}
                            autoComplete='address'
                            className='p-1.5 border shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                      </div>

                      <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                        <label
                          htmlFor='city'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Miasto
                        </label>
                        <div className='mt-1'>
                          <input
                            type='text'
                            name='city'
                            id='city'
                            value={city || ''}
                            required
                            onChange={(e) => setCity(e.target.value)}
                            className='p-1.5 border shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                      </div>

                      <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                        <label
                          htmlFor='zip'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Kod pocztowy
                        </label>
                        <div className='mt-1'>
                          <input
                            type='text'
                            name='zip'
                            id='zip'
                            value={postalCode || ''}
                            required
                            onChange={(e) => setPostalCode(e.target.value)}
                            autoComplete='postal-code'
                            className='p-1.5 border shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                      </div>

                      <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                        <label
                          htmlFor='state'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Kraj
                        </label>
                        <div className='mt-1'>
                          <input
                            type='text'
                            name='country'
                            id='country'
                            value={country || ''}
                            required
                            onChange={(e) => setCountry(e.target.value)}
                            className='p-1.5 border shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='mt-6 flex justify-end'>
                      <button
                        type='button'
                        className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      >
                        Anuluj
                      </button>
                      <button
                        type='submit'
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      >
                        Dalej
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ShippingScreen
