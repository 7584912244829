import ClipLoader from 'react-spinners/ClipLoader'

const Loader = () => {
  return (
    <div className='fixed inset-1/2'>
      <ClipLoader color='#e27300' size='60px' />
    </div>
  )
}

export default Loader
