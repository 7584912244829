import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Heading from 'common/components/Heading'
import Alerts from 'common/components/Alerts'
import Loader from 'common/components/Loader'
import { listProductDetails, updateProduct } from 'state/actions/productActions'
import { PRODUCT_UPDATE_RESET } from 'state/constants/productConstants'

const EditproductScreen = ({ match, history }) => {
  const productID = match.params.id

  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [price, setPrice] = useState(0)
  const [capacity, setCapacity] = useState(1)
  const [quantity, setQuantity] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [subcategory, setSubcategory] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productDetails = useSelector((state) => state.productDetails)
  const { product, loading, error } = productDetails

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/produkty')
    } else {
      if (!product.name || product._id !== productID) {
        dispatch(listProductDetails(productID))
      } else {
        setName(product.name)
        setImage(product.image)
        setPrice(product.price)
        setCapacity(product.capacity)
        setQuantity(product.quantity)
        setDescription(product.description)
        setCategory(product.category)
        setSubcategory(product.subcategory)
      }
    }
  }, [dispatch, history, productID, product, successUpdate])

  const handleSelect = (e) => {
    const updatedOptions = [...e.target.options]
      .filter((option) => option.selected)
      .map((x) => x.value)
    setSubcategory(updatedOptions)
  }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateProduct({
        _id: productID,
        name,
        image,
        price,
        quantity,
        capacity,
        description,
        category,
        subcategory,
      })
    )
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Edytuj produkt</Heading>
          <div className='mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
            <div className='md:grid md:grid-cols-3 md:gap-6'>
              <div className='md:col-span-1'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Informacje
                </h3>
                <p className='mt-1 text-sm text-gray-500'>
                  Formularz umożliwiający zmianę danych produktu.
                </p>
              </div>
              <div className='mt-5 md:mt-0 md:col-span-2'>
                {loadingUpdate && <Loader />}
                {error && <Alerts error>{error}</Alerts>}
                {errorUpdate && <Alerts error>{errorUpdate}</Alerts>}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Alerts attention>Błąd!</Alerts>
                ) : (
                  <form onSubmit={submitHandler}>
                    <div className='grid grid-cols-6 gap-6'>
                      <div className='sm:col-span-6'>
                        <label
                          htmlFor='name'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Nazwa
                        </label>
                        <div className='mt-1 flex rounded-md shadow-sm'>
                          <input
                            type='text'
                            name='name'
                            id='name'
                            className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-6'>
                        <label className='block text-sm font-medium text-gray-700'>
                          Zdjęcie
                        </label>
                        <div className='mt-1 flex items-center space-x-5'>
                          <img
                            className='h-12 w-12 rounded-full'
                            src={image}
                            alt=''
                          />
                          <label
                            htmlFor='image-file'
                            className='bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            <span>Zmień</span>
                            <input
                              id='image-file'
                              name='file-upload'
                              type='file'
                              className='sr-only'
                              onChange={uploadFileHandler}
                            />
                          </label>
                        </div>
                      </div>
                      {uploading && <Loader />}

                      <div className='sm:col-span-2'>
                        <label
                          htmlFor='price'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Cena
                        </label>
                        <div className='mt-1 flex rounded-md shadow-sm'>
                          <input
                            type='text'
                            name='price'
                            id='price'
                            className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                            value={price || ''}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-2'>
                        <label
                          htmlFor='capacity'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Pojemność
                        </label>
                        <div className='mt-1 flex rounded-md shadow-sm'>
                          <input
                            type='text'
                            name='capacity'
                            id='capacity'
                            className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                            value={capacity || ''}
                            onChange={(e) => setCapacity(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-2'>
                        <label
                          htmlFor='quantity'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Ilość
                        </label>
                        <div className='mt-1 flex rounded-md shadow-sm'>
                          <input
                            type='text'
                            name='quantity'
                            id='quantity'
                            className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                            value={quantity || ''}
                            onChange={(e) => setQuantity(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-6'>
                        <label
                          htmlFor='description'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Opis
                        </label>
                        <div className='mt-1'>
                          <textarea
                            id='description'
                            name='description'
                            rows={6}
                            className='shadow-sm focus:ring-yellow-500 focus:border-yellow-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='category'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Kategoria
                        </label>
                        <select
                          id='category'
                          name='category'
                          className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                          value={category || ''}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option>atramenty</option>
                          <option>zaopatrzenie</option>
                          <option>grafiki</option>
                          <option>akcesoria cukiernicze</option>
                        </select>
                      </div>

                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='subcategory'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Podkategoria
                        </label>
                        <select
                          id='subcategory'
                          name='subcategory'
                          className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                          multiple={true}
                          onChange={handleSelect}
                          value={subcategory}
                          options={product?.subcategory}
                        >
                          {product?.subcategory?.map((item, i) => {
                            return (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            )
                          })}
                        </select>
                      </div>

                      <div className='col-span-6 sm:col-span-6'>
                        <div className='flex justify-end'>
                          <button
                            onClick={() => history.push('/admin/produkty')}
                            type='button'
                            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            Anuluj
                          </button>
                          <button
                            type='submit'
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            Zaktualizuj
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default EditproductScreen
