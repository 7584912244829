import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from 'common/components/Loader'
import Heading from 'common/components/Heading'
import Alerts from 'common/components/Alerts'
import { listCategories } from 'state/actions/categoryActions'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CategoriesPage = () => {
  const dispatch = useDispatch()

  const categoryList = useSelector((state) => state.categoryList)
  const { categories, loading, error } = categoryList

  useEffect(() => {
    dispatch(listCategories())
  }, [dispatch])

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Sklep</Heading>
          <div className='mt-6 rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px'>
            {loading ? (
              <Loader />
            ) : error ? (
              <Alerts attention title={error} />
            ) : (
              categories.map((category, actionIdx) => (
                <div
                  key={category.name}
                  className={classNames(
                    actionIdx === 0
                      ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                      : '',
                    actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                    actionIdx === category.length - 2 ? 'sm:rounded-bl-lg' : '',
                    actionIdx === category.length - 1
                      ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                      : '',
                    'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-yellow-500'
                  )}
                >
                  <div>
                    <img
                      className='h-12 w-12'
                      src={category.image}
                      alt={category.name}
                    />
                  </div>
                  <div className='mt-8'>
                    <h3 className='text-lg font-medium'>
                      <a
                        href={`/sklep/${category.name.replace(/ /g, '-')}`}
                        className='focus:outline-none'
                      >
                        {/* Extend touch target to entire panel */}
                        <span className='absolute inset-0' aria-hidden='true' />
                        {category.name}
                      </a>
                    </h3>
                    <p className='mt-2 text-sm text-gray-500'>
                      {category.description}
                    </p>
                  </div>
                  <span
                    className='pointer-events-none absolute top-6 right-6 text-yellow-500 group-hover:text-yellow-400'
                    aria-hidden='true'
                  >
                    <svg
                      className='h-6 w-6'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
                    </svg>
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default CategoriesPage
