import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ViewGridIcon as ViewGridIconSolid,
  ViewListIcon,
  SearchIcon,
} from '@heroicons/react/solid'
import { listVideo } from 'state/actions/videoActions'
import DetailsSidebar from 'pages/MoviesPage/components/DetailsSidebar'
import Heading from 'common/components/Heading'
import Loader from 'common/components/Loader'
import Alerts from 'common/components/Alerts'

function classNames(...classes) {
  return classes.filter(Boolean).join('')
}

const tabs = [
  { id: 1, name: 'wszystkie' },
  { id: 2, name: 'szkoleniowe' },
  { id: 3, name: 'wdrożeniowe' },
  { id: 4, name: 'serwisowe' },
  { id: 5, name: 'inspiracje' },
]

function MoviesScreen() {
  const [isShowing, setIsShowing] = useState(false)
  const [activeIdx, setActiveIdx] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [category, setCategory] = useState('')
  const [currentFile, setCurrentFile] = useState('')
  const [activeTab, setActiveTab] = useState(0)

  const dispatch = useDispatch()

  const videoList = useSelector((state) => state.videoList)
  const { loading, error, video } = videoList

  useEffect(() => {
    dispatch(listVideo(keyword, category))
  }, [dispatch, keyword, category])

  const seeDetails = (file) => {
    setCurrentFile(file)
  }

  const handleActiveOnClick = (index) => {
    setActiveIdx(index)
  }

  const toggleActiveTab = (index) => {
    setActiveTab(index)
  }

  return (
    <div className='flex-1 flex items-stretch overflow-hidden'>
      <main className='flex-1 overflow-y-auto'>
        <div className='pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex'>
            <div className='flex-1'>
              <Heading>Filmy</Heading>
            </div>
            <div className='hidden sm:block'>
              <label htmlFor='search-machine' className='sr-only'>
                Szukaj filmu
              </label>
              <div className='flex rounded-md '>
                <div className='relative flex-grow focus-within:z-10'>
                  <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                    <SearchIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                  <input
                    type='text'
                    name='search-machine'
                    id='search-machine'
                    className='focus:ring-yellow-500 focus:border-yellow-500 w-full rounded-none rounded-md pl-10 block text-sm border-gray-300'
                    placeholder='Szukaj filmu'
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='ml-6 bg-gray-100 p-0.5 rounded-lg flex items-center sm:hidden'>
              <button
                type='button'
                className='p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
              >
                <ViewListIcon className='h-5 w-5' aria-hidden='true' />
                <span className='sr-only'>Use list view</span>
              </button>
              <button
                type='button'
                className='ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
              >
                <ViewGridIconSolid className='h-5 w-5' aria-hidden='true' />
                <span className='sr-only'>Use grid view</span>
              </button>
            </div>
          </div>

          {/* Tabs */}
          <div className='mt-3 sm:mt-2'>
            <div className='sm:hidden'>
              <label htmlFor='tabs' className='sr-only'>
                Wybierz zakładkę
              </label>
              <select
                id='tabs'
                name='tabs'
                className='block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md'
                defaultValue='Recently Viewed'
              >
                <option>Wszystkie</option>
                <option>Ostatnio dodane</option>
                <option>Ulubione</option>
              </select>
            </div>
            <div className='hidden sm:block'>
              <div className='flex items-center border-b border-gray-300'>
                <nav
                  className='flex-1 -mb-px flex space-x-6 xl:space-x-8'
                  aria-label='Tabs'
                >
                  {tabs.map((tab, index) => (
                    <button
                      key={tab.name}
                      aria-current={activeTab === index ? 'page' : undefined}
                      onClick={(e) => {
                        tab.name === 'wszystkie'
                          ? setCategory('')
                          : setCategory(tab.name)
                        toggleActiveTab(index)
                      }}
                      className={classNames(
                        activeTab === index
                          ? 'border-yellow-500 text-yellow-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        ' whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
                <div className='hidden ml-6 bg-gray-100 p-0.5 rounded-lg items-center sm:flex'>
                  <button
                    type='button'
                    className='p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
                  >
                    <ViewListIcon className='h-5 w-5' aria-hidden='true' />
                    <span className='sr-only'>Use list view</span>
                  </button>
                  <button
                    type='button'
                    className='ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
                  >
                    <ViewGridIconSolid className='h-5 w-5' aria-hidden='true' />
                    <span className='sr-only'>Use grid view</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery */}
          <section className='mt-8 pb-16' aria-labelledby='gallery-heading'>
            <h2 id='gallery-heading' className='sr-only'>
              Wszystkie filmy
            </h2>
            {loading ? (
              <Loader />
            ) : error ? (
              <Alerts attention title='Nie masz żadnych filmów' />
            ) : (
              <ul
                className={classNames(
                  'grid grid-cols gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 xl:gap-x-8',
                  isShowing ? ' lg:grid-cols-3' : 'lg:grid-cols-4'
                )}
              >
                {video.map((file, index) => (
                  <li
                    key={file.name}
                    className='relative'
                    onClick={() => {
                      isShowing !== true && setIsShowing(true)
                      seeDetails(file)
                    }}
                  >
                    <div
                      className={classNames(
                        activeIdx === index
                          ? 'ring-2 ring-offset-2 ring-yellow-500'
                          : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-yellow-500',
                        ' group block w-full aspect-w-12 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden'
                      )}
                      onClick={() => handleActiveOnClick(index)}
                    >
                      <img
                        src={file.cover}
                        alt={file.name}
                        className='object-cover pointer-events-none'
                      />
                      <button
                        type='button'
                        className='absolute inset-0 focus:outline-none'
                      >
                        <span className='sr-only'>
                          Zobacz szczegóły dla {file.name}
                        </span>
                      </button>
                    </div>
                    <p className='mt-2 block text-sm font-medium text-gray-900 pointer-events-none'>
                      {file.name}
                    </p>
                    <p className='block text-sm font-medium text-gray-500 pointer-events-none'>
                      {file.length}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </section>
        </div>
      </main>

      <DetailsSidebar
        currentFile={currentFile}
        isShowing={isShowing}
        setIsShowing={setIsShowing}
      />
    </div>
  )
}

export default MoviesScreen
