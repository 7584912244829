import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productCreateReducer,
  productUpdateReducer,
  productNewsReducer,
  productDetailsReducer,
  productDeleteReducer,
  productRelatedReducer,
} from './reducers/productReducers.js'
import {
  userLoginReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
} from './reducers/userReducers.js'
import { cartReducers } from './reducers/cartReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderListReducer,
  orderLastReducer,
  orderAllReducer,
  orderDeliverReducer,
} from './reducers/orderReducers'
import {
  machineListReducer,
  machineCreateReducer,
  machineUpdateReducer,
  machineDetailsReducer,
  machinesAllReducer,
} from './reducers/machineReducers'
import {
  graphicListReducer,
  graphicToggleFavoriteReducer,
} from './reducers/graphicReducers'
import { videoListReducer, videoDetailsReducer } from './reducers/videoReducers'
import { categoryListReducer } from './reducers/categoryReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productNews: productNewsReducer,
  productRelated: productRelatedReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  cart: cartReducers,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderList: orderListReducer,
  orderLast: orderLastReducer,
  orderAll: orderAllReducer,
  machineList: machineListReducer,
  machineCreate: machineCreateReducer,
  machineUpdate: machineUpdateReducer,
  machineDetails: machineDetailsReducer,
  allMachines: machinesAllReducer,
  graphicList: graphicListReducer,
  graphicToggleFavorite: graphicToggleFavoriteReducer,
  videoList: videoListReducer,
  videoDetails: videoDetailsReducer,
  categoryList: categoryListReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

const paymentMethodFromStorage = localStorage.getItem('paymentMethod')
  ? JSON.parse(localStorage.getItem('paymentMethod'))
  : {}

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
