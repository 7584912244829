import {
  FingerPrintIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  TrashIcon,
} from '@heroicons/react/solid'

const StackedList = ({ data, isAdmin, url, deleteHandler }) => {
  return (
    <div className='bg-white shadow overflow-hidden sm:rounded-md'>
      <ul className='divide-y divide-gray-200'>
        {data.map((item) => (
          <li className='relative' key={item._id}>
            <a
              href={
                !isAdmin
                  ? `/serwis/maszyna/${item._id}`
                  : isAdmin && url === '/admin/maszyny'
                  ? `/admin/maszyna/${item._id}/edycja`
                  : isAdmin && url === '/admin/produkty'
                  ? `/admin/produkt/${item._id}/edycja`
                  : '/'
              }
              className='block hover:bg-gray-50'
            >
              <div className='flex items-center px-4 py-4 sm:px-6'>
                <div className='min-w-0 flex-1 flex items-center'>
                  <div className='flex-shrink-0'>
                    <img
                      className='h-12 w-12 rounded-full'
                      src={item.image}
                      alt={item.name}
                    />
                  </div>
                  <div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
                    <div>
                      <p className='text-sm font-medium text-yellow-600 truncate'>
                        {item.name}
                      </p>
                      <p className='mt-2 flex items-center text-sm text-gray-500'>
                        <FingerPrintIcon
                          className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                        <span className='truncate'>
                          {item.serialNumber || item._id}
                        </span>
                      </p>
                    </div>
                    {'guarantee' in item ? (
                      <div className='hidden md:block'>
                        <div>
                          <p className='text-sm text-gray-900'>
                            Status gwarancji
                          </p>
                          {new Date(item.guarantee) >= new Date() ? (
                            <p className='mt-2 flex items-center text-sm text-gray-500'>
                              <CheckCircleIcon
                                className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                                aria-hidden='true'
                              />
                              <time dateTime={item.guarantee}>
                                Ważna do:{' '}
                                {new Date(item.guarantee).toLocaleDateString(
                                  'pl-PL'
                                )}
                              </time>
                            </p>
                          ) : (
                            <p className='mt-2 flex items-center text-sm text-gray-500'>
                              <CheckCircleIcon
                                className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
                                aria-hidden='true'
                              />
                              <time dateTime={item.guarantee}>
                                Nieważna od:{' '}
                                {new Date(item.guarantee).toLocaleDateString(
                                  'pl-PL'
                                )}
                              </time>
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {url === '/admin/produkty' ? (
                      <button
                        onClick={() => deleteHandler(item._id)}
                        type='button'
                        className='absolute inset-y-0 right-10 mr-2 text-sm inline-flex items-center justify-center text-gray-400 hover:text-gray-500'
                      >
                        <TrashIcon
                          className='-ml-1 mr-1 h- w-4'
                          aria-hidden='true'
                        />
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div>
                  <button type='button'>
                    <ChevronRightIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default StackedList
