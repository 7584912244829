const ChecoutSteps = ({ step1, step2, step3 }) => {
  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol className='bg-white rounded-md shadow px-6 flex space-x-4'>
        <li className='flex'>
          {step1 ? (
            <div className='flex items-center'>
              <svg
                className='flex-shrink-0 w-6 h-full text-gray-200'
                viewBox='0 0 24 44'
                preserveAspectRatio='none'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
              >
                <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
              </svg>
              <a
                href={'/wysylka'}
                className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
              >
                Dane do wysyłki
              </a>
            </div>
          ) : (
            <div className='flex items-center'>
              <svg
                className='flex-shrink-0 w-6 h-full text-gray-200'
                viewBox='0 0 24 44'
                preserveAspectRatio='none'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
              >
                <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
              </svg>
              <div className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'>
                Dane do wysyłki
              </div>
            </div>
          )}
        </li>

        <li className='flex'>
          {step2 ? (
            <div className='flex items-center'>
              <svg
                className='flex-shrink-0 w-6 h-full text-gray-200'
                viewBox='0 0 24 44'
                preserveAspectRatio='none'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
              >
                <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
              </svg>
              <a
                href={'/platnosc'}
                className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
              >
                Płatności
              </a>
            </div>
          ) : (
            <div className='flex items-center'>
              <svg
                className='flex-shrink-0 w-6 h-full text-gray-200'
                viewBox='0 0 24 44'
                preserveAspectRatio='none'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
              >
                <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
              </svg>
              <div className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'>
                Płatności
              </div>
            </div>
          )}
        </li>

        <li className='flex'>
          {step3 ? (
            <div className='flex items-center'>
              <svg
                className='flex-shrink-0 w-6 h-full text-gray-200'
                viewBox='0 0 24 44'
                preserveAspectRatio='none'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
              >
                <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
              </svg>
              <a
                href={'/zamowienie'}
                className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
              >
                Zamówienie
              </a>
            </div>
          ) : (
            <div className='flex items-center'>
              <svg
                className='flex-shrink-0 w-6 h-full text-gray-200'
                viewBox='0 0 24 44'
                preserveAspectRatio='none'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
              >
                <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
              </svg>
              <div className='ml-4 text-sm font-medium text-gray-300'>
                Zamówienie
              </div>
            </div>
          )}
        </li>
      </ol>
    </nav>
  )
}

export default ChecoutSteps
