import {
  VIDEO_LIST_REQUEST,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAIL,
  VIDEO_DETAILS_SUCCESS,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_FAIL,
} from '../constants/videoConstants'

export const videoListReducer = (state = { video: [] }, action) => {
  switch (action.type) {
    case VIDEO_LIST_REQUEST:
      return {
        loading: true,
        video: [],
      }
    case VIDEO_LIST_SUCCESS:
      return {
        loading: false,
        video: action.payload,
      }
    case VIDEO_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const videoDetailsReducer = (state = { video: {} }, action) => {
  switch (action.type) {
    case VIDEO_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case VIDEO_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        video: action.payload,
      }
    case VIDEO_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
