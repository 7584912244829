import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNewProducts } from 'state/actions/productActions'
import Subheading from 'common/components/Subheading'

const NewProducts = () => {
  const dispatch = useDispatch()

  const productNews = useSelector((state) => state.productNews)
  const { products } = productNews

  useEffect(() => {
    dispatch(getNewProducts())
  }, [dispatch])

  return (
    <>
      <Subheading>Nowości</Subheading>
      <ul className='grid grid-cols-1 gap-6'>
        {products.map((product) => (
          <li
            key={product.name}
            className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'
          >
            <a href={`/produkt/${product._id}`}>
              <img
                className='rounded-t-lg'
                src={product.image}
                alt={product.name}
              />
            </a>
            <div className='flex-1 flex flex-col p-4'>
              <h3 className='text-gray-900 text-sm font-medium'>
                {product.name}
              </h3>
              <dl className='mt-2 flex-grow flex flex-col justify-between'>
                <dt className='sr-only'>Price</dt>
                <dd className='text-gray-500 text-sm'>{product.price} zł</dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default NewProducts
