import { useEffect, useState, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  SearchIcon,
  SortAscendingIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline'
import { listProducts } from 'state/actions/productActions.js'
import { listCategories } from 'state/actions/categoryActions.js'
import Product from 'pages/ShopPage/components/Product'
import Heading from 'common/components/Heading'
import Alerts from 'common/components/Alerts'
import Loader from 'common/components/Loader'
import Pagination from 'common/components/Pagination'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ShopScreen = ({ match }) => {
  const [keyword, setKeyword] = useState('')
  const [subcategoryFilter, setSubcategoryFilter] = useState('')
  const [pageNumber, setPageNumber] = useState(1)

  let category = match.params.category
  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  const categoryList = useSelector((state) => state.categoryList)
  const { categories } = categoryList

  let sub = categories.filter((cat) => cat.name === category.replace('-', ' '))
  let subcategory = sub[0]?.subcategory

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber, category, subcategoryFilter))
    dispatch(listCategories())
  }, [dispatch, keyword, pageNumber, category, subcategoryFilter])

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Link
            to='/sklep'
            className='mb-3 inline-flex items-center space-x-3 text-sm font-medium text-gray-900'
          >
            <svg
              className='-ml-2 h-5 w-5 text-gray-400'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
            >
              <path
                fillRule='evenodd'
                d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                clipRule='evenodd'
              />
            </svg>
            <span>Kategorie</span>
          </Link>
          <Heading>Sklep</Heading>
        </div>
        <div className='flex flex-row justify-end max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div>
            <label htmlFor='search-product' className='sr-only'>
              Szukaj produktów
            </label>
            <div className='flex rounded-md shadow-sm'>
              <div className='relative flex-grow focus-within:z-10'>
                <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                  <SearchIcon
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </div>
                <input
                  type='text'
                  name='search-product'
                  id='search-product'
                  className='focus:ring-yellow-500 focus:border-yellow-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300'
                  placeholder='Szukaj'
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <input
                  type='text'
                  name='search-product'
                  id='search-machine'
                  className='p-2.5 hidden focus:ring-yellow-500 focus:border-yellow-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300'
                  placeholder='Szukaj produktów'
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
              <Menu as='div' className='relative inline-block text-left'>
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className='-ml-px relative inline-flex items-center px-4 py-2.5 border border-yellow-500 text-sm font-medium rounded-r-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500'>
                        <SortAscendingIcon
                          className='h-5 w-5 text-white'
                          aria-hidden='true'
                        />
                        <span className='ml-2'>Filtruj podkategorie</span>
                        <ChevronDownIcon
                          className='ml-2.5 -mr-1.5 h-5 w-5 text-white'
                          aria-hidden='true'
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items
                        static
                        className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                      >
                        {subcategory?.map((cat) => (
                          <Menu.Item key={cat}>
                            {({ active }) => (
                              <span
                                onClick={() => setSubcategoryFilter(cat)}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  ' block px-4 py-2 text-sm'
                                )}
                              >
                                {cat}
                              </span>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Alerts attention title={error} />
          ) : (
            <ul className='mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
              {products.map((product, i) => (
                <Product key={i} {...product} />
              ))}
            </ul>
          )}
        </div>
        <Pagination
          pages={pages}
          page={page}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          keyword={keyword ? keyword : ''}
        />
      </div>
    </main>
  )
}

export default ShopScreen
