import axios from 'axios'
import { useState, useEffect } from 'react'
import { PaperClipIcon } from '@heroicons/react/outline'
import { useSelector, useDispatch } from 'react-redux'
import { listMachineDetails, listMachines } from 'state/actions/machineActions'
import Heading from 'common/components/Heading'
import ReportServiceForm from 'common/components/ReportServiceForm'
import Notifications from 'common/components/Notifications'

const MachineScreen = ({ match }) => {
  const machineID = match.params.id

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState(userInfo.email)
  const [serialNumber, setSerialNumber] = useState('')
  const [isShowing, setIsShowing] = useState(false)

  const dispatch = useDispatch()

  const machineDetails = useSelector((state) => state.machineDetails)
  const { machine } = machineDetails

  const machineList = useSelector((state) => state.machineList)
  const { machines } = machineList

  const { supplies } = machine

  useEffect(() => {
    dispatch(listMachineDetails(machineID))
    dispatch(listMachines())
    setSerialNumber(machine.serialNumber)
  }, [dispatch, machineID, machine.serialNumber])

  const sendEmail = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.post(
        'http://127.0.0.1:5000/api/email/warranty',
        {
          email,
          serialNumber,
        },
        config
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleSend = async (e) => {
    e.preventDefault()
    sendEmail()
    setOpen(false)
    setIsShowing(true)
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>{machine.name}</Heading>
        </div>
        <div className='py-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='grid grid-cols-1 gap-4 items-start lg:grid-cols-4 lg:gap-8'>
            {/* Left column */}
            <div className='grid grid-cols-1 gap-4 lg:col-span-3'>
              <div className='bg-white shadow overflow-hidden sm:rounded-lg'>
                <div className='relative px-4 py-5 sm:px-6'>
                  <h3 className='text-lg leading-6 font-medium text-gray-900'>
                    Informacje o urządzeniu
                  </h3>
                  <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                    Szczegóły techniczne zakupionej maszyny
                  </p>
                  <button
                    type='button'
                    onClick={() => setOpen(true)}
                    className='block sm:absolute top-7 right-5 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                  >
                    Zgłoś serwis
                  </button>
                </div>

                <div className='border-t border-gray-200'>
                  <dl className='divide-y divide-gray-200'>
                    <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Numer seryjny
                      </dt>
                      <dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                        <span className='flex-grow'>
                          {machine.serialNumber}
                        </span>
                      </dd>
                    </div>
                    <div className='py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4 px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Data zakupu
                      </dt>
                      <dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                        <span className='flex-grow'>
                          {new Date(machine.purchaseDate).toLocaleDateString(
                            'pl-PL'
                          )}
                        </span>
                      </dd>
                    </div>
                    <div className='py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4 px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Gwarancja
                      </dt>
                      <dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                        <span className='flex-grow'>
                          {new Date(machine.guarantee).toLocaleDateString(
                            'pl-PL'
                          )}
                        </span>
                        <span className='ml-4 flex-shrink-0'>
                          <button
                            onClick={handleSend}
                            type='button'
                            className='bg-white rounded-md font-medium text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            Przedłuż
                          </button>
                        </span>
                      </dd>
                    </div>
                    <div className='py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4 px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Data ostatniego przeglądu
                      </dt>
                      <dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                        <span className='flex-grow'>
                          {new Date(
                            machine.lastInspectionDate
                          ).toLocaleDateString('pl-PL')}
                        </span>
                        {/* <span className='ml-4 flex-shrink-0'>
                          <button
                            type='button'
                            className='bg-white rounded-md font-medium text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            Zamów
                          </button>
                        </span> */}
                      </dd>
                    </div>
                    {machine.files?.length !== 0 && (
                      <div className='py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4 sm:px-6'>
                        <dt className='text-sm font-medium text-gray-500'>
                          Załączniki
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                          <ul className='border border-gray-200 rounded-md divide-y divide-gray-200'>
                            {machine.files?.map((file) => (
                              <li className='pl-3 pr-4 py-3 flex items-center justify-between text-sm'>
                                <div className='w-0 flex-1 flex items-center'>
                                  <PaperClipIcon
                                    className='flex-shrink-0 h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                  <span className='ml-2 flex-1 w-0 truncate'>
                                    {file.name}
                                  </span>
                                </div>
                                <div className='ml-4 flex-shrink-0'>
                                  <a
                                    href={file.url}
                                    className='bg-white rounded-md font-medium text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                                  >
                                    Pobierz
                                  </a>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
            </div>

            {/* Right column */}
            <ul className='grid grid-cols-1 gap-6'>
              {supplies?.map((product, index) => (
                <a href={`/produkt/${product._id}`}>
                  <li
                    key={index}
                    className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'
                  >
                    <img
                      className='rounded-t-lg'
                      src={product.image}
                      alt={product.name}
                    />
                    <div className='flex-1 flex flex-col p-4'>
                      <h3 className='text-gray-900 text-sm font-medium'>
                        {product.name}
                      </h3>
                    </div>
                  </li>
                </a>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {open && (
        <ReportServiceForm
          open={open}
          setOpen={setOpen}
          setIsShowing={setIsShowing}
          defaultMachine={machine}
          machines={machines}
        />
      )}
      {isShowing && (
        <Notifications
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          title={'Wiadomość wysłana!'}
          content={'Wkrótce odezwiemy się do Ciebie!'}
        />
      )}
    </main>
  )
}

export default MachineScreen
