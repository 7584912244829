import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CheckoutSteps from 'common/components/CheckoutSteps'
import { Link } from 'react-router-dom'
import Alerts from 'common/components/Alerts'
import Loader from 'common/components/Loader'
import { createOrder } from 'state/actions/orderActions'
import { ORDER_CREATE_RESET } from 'state/constants/orderConstants'

const PlaceOrderScreen = ({ history }) => {
  const [checked, setChecked] = useState(true)

  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)

  // Przeliczenie ostatecznej ceny
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce(
      (acc, item) => acc + (item.price - item.discount) * item.qty,
      0
    )
  )
  cart.shippingPrice = 35
  cart.taxPrice = addDecimals(Number((0.23 * cart.itemsPrice).toFixed(2)))
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, loading, success, error } = orderCreate

  useEffect(() => {
    if (success) {
      history.push(`/zamowienie/${order._id}`)
      dispatch({ type: ORDER_CREATE_RESET })
    }
    // eslint-disable-next-line
  }, [history, success])

  const placeOrderHandler = () => {
    if (checked) {
      dispatch(
        createOrder({
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
        })
      )
    }
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <CheckoutSteps step1 step2 step3 />
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-6 flex flex-col lg:flex-row gap-4 relative'>
            <div className='w-full lg:w-3/5 bg-white shadow overflow-hidden sm:rounded-lg sm:overflow-hidden'>
              <div className='px-4 py-5 sm:px-6'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  Podsumowanie
                </h3>
                <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                  Wszystkie informacje na temat Twojego zamówienia
                </p>
              </div>
              <div className='border-t border-gray-200'>
                <dl>
                  <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Adres do wysyłki
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {cart.shippingAddress.address},<br />
                      {cart.shippingAddress.city}{' '}
                      {cart.shippingAddress.postalCode},{' '}
                      {cart.shippingAddress.country}
                    </dd>
                  </div>
                  <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Metoda płatności
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {cart.paymentMethod}
                    </dd>
                  </div>
                  <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Zamówione produkty:
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {cart.cartItems.length === 0 ? (
                        <Alerts>Twój koszyk jest pusty</Alerts>
                      ) : (
                        <ul className=''>
                          {cart.cartItems.map((item, index) => (
                            <li key={index}>
                              <Link to={`/produkt/${item.product}`}>
                                {item.name}
                              </Link>
                              <span>
                                {' '}
                                x {item.qty} ={' '}
                                {item.qty * (item.price - item.discount)} zł
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className='w-full lg:w-2/5'>
              <div className='p-6 flex flex-col text-center gap-6 bg-white rounded-lg shadow lg:sticky top-2.5'>
                <h2 className='text-xl font-semibold text-gray-900'>
                  Twoje zamówienie
                </h2>
                <div>
                  <dl className='mt-2 border-t border-b border-gray-200 divide-y divide-gray-200'>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>
                        Cena wszystkich produktów
                      </dt>
                      <dd className='text-gray-900'>{cart.itemsPrice} zł</dd>
                    </div>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>Koszt wysyłki</dt>
                      <dd className='text-gray-900'>{cart.shippingPrice} zł</dd>
                    </div>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>Podatek 23%</dt>
                      <dd className='text-gray-900'>{cart.taxPrice} zł</dd>
                    </div>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>Łącznie</dt>
                      <dd className='text-lg text-gray-900'>
                        {cart.totalPrice} zł
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className='my-4 flex items-center justify-between'>
                  <div className='flex items-center'>
                    <input
                      name='acceptance'
                      type='checkbox'
                      className='h-4 w-4 text-yellow-600 focus:ring-yellow-500 border-gray-300 rounded'
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      required='required'
                    />
                    <label
                      htmlFor='acceptance'
                      className='ml-2.5 block text-left text-sm text-gray-500'
                    >
                      Czy akceptacja tutaj jest potrzebna z prawnego punktu
                      widzenia ???
                    </label>
                  </div>
                </div>
                {error && <Alerts error>{error}</Alerts>}
                {loading && <Loader />}
                <button
                  onClick={placeOrderHandler}
                  type='submit'
                  className='w-full inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                >
                  Kupuję i płacę
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PlaceOrderScreen
