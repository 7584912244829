import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import {
  ArrowRightIcon,
  HeartIcon as HeartIconOutline,
} from '@heroicons/react/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid'
import {
  ViewGridIcon as ViewGridIconSolid,
  ViewListIcon,
  SearchIcon,
} from '@heroicons/react/solid'
import {
  listGraphics,
  toggleFavoriteGraphic,
} from 'state/actions/graphicActions'
import Loader from 'common/components/Loader'
import Alerts from 'common/components/Alerts'
import Heading from 'common/components/Heading'

const tabs = [
  { id: 1, name: 'wszystkie' },
  { id: 2, name: 'ostatnio dodane' },
  { id: 3, name: 'ulubione' },
]

function convertBytes(b) {
  return b / (1024 * 1024)
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function LibraryScreen() {
  const location = useLocation()

  const [isShowing, setIsShowing] = useState(false)
  const [keyword, setKeyword] = useState('')
  // eslint-disable-next-line
  const [category, setCategory] = useState(location?.state?.category || '')
  const [activeIdx, setActiveIdx] = useState(0)
  const [currentFile, setCurrentFile] = useState()
  const [activeTab, setActiveTab] = useState(location?.state?.activeTab || 0)

  const dispatch = useDispatch()

  const graphicList = useSelector((state) => state.graphicList)
  const { loading, error, graphics } = graphicList

  const graphicToggleFavorite = useSelector(
    (state) => state.graphicToggleFavorite
  )
  const { loading: loadingToggle, success: successToggle } =
    graphicToggleFavorite

  useEffect(() => {
    dispatch(listGraphics(keyword, category))
  }, [dispatch, keyword, category, successToggle])

  const handleActiveOnClick = (index) => {
    setActiveIdx(index)
  }

  const toggleActiveTab = (index) => {
    setActiveTab(index)
  }

  const isFavoriteHandler = (id) => {
    dispatch(toggleFavoriteGraphic(id))
  }

  const downloadFile = async (currentFile) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/zip',
        },
        responseType: 'blob',
      }

      await axios
        .get('https://myimago.pl' + currentFile?.graphic?.path, config)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', currentFile?.graphic?.name + '.zip')
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className='flex-1 flex items-stretch overflow-hidden'>
      <main className='flex-1 overflow-y-auto'>
        <div className='pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex'>
            <div className='flex-1'>
              <Heading>Biblioteka</Heading>
            </div>
            <div className='hidden sm:block'>
              <label htmlFor='search-machine' className='sr-only'>
                Szukaj grafiki
              </label>
              <div className='flex rounded-md '>
                <div className='relative flex-grow focus-within:z-10'>
                  <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                    <SearchIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                  <input
                    type='text'
                    name='search'
                    id='search'
                    className='focus:ring-yellow-500 focus:border-yellow-500 w-full rounded-none rounded-md pl-10 block text-sm border-gray-300'
                    placeholder='Szukaj grafiki'
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='ml-6 bg-gray-100 p-0.5 rounded-lg flex items-center sm:hidden'>
              <button
                type='button'
                className='p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
              >
                <ViewListIcon className='h-5 w-5' aria-hidden='true' />
                <span className='sr-only'>Use list view</span>
              </button>
              <button
                type='button'
                className='ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
              >
                <ViewGridIconSolid className='h-5 w-5' aria-hidden='true' />
                <span className='sr-only'>Use grid view</span>
              </button>
            </div>
          </div>

          {/* Tabs */}
          <div className='mt-3 sm:mt-2'>
            <div className='sm:hidden'>
              <label htmlFor='tabs' className='sr-only'>
                Wybierz zakładkę
              </label>
              <select
                id='tabs'
                name='tabs'
                className='block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md'
                defaultValue='Recently Viewed'
              >
                <option>Wszystkie</option>
                <option>Ostatnio dodane</option>
                <option>Ulubione</option>
              </select>
            </div>
            <div className='hidden sm:block'>
              <div className='flex items-center border-b border-gray-300'>
                <nav
                  className='flex-1 -mb-px flex space-x-6 xl:space-x-8'
                  aria-label='Tabs'
                >
                  {tabs.map((tab, index) => (
                    <button
                      key={tab.name}
                      aria-current={activeTab === index ? 'page' : undefined}
                      onClick={(e) => {
                        tab.name === 'wszystkie'
                          ? setCategory('')
                          : setCategory(tab.name.replace(' ', '-'))
                        toggleActiveTab(index)
                      }}
                      className={classNames(
                        activeTab === index
                          ? 'border-yellow-500 text-yellow-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        ' whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
                <div className='hidden ml-6 bg-gray-100 p-0.5 rounded-lg items-center sm:flex'>
                  <button
                    type='button'
                    className='p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
                  >
                    <ViewListIcon className='h-5 w-5' aria-hidden='true' />
                    <span className='sr-only'>Use list view</span>
                  </button>
                  <button
                    type='button'
                    className='ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'
                  >
                    <ViewGridIconSolid className='h-5 w-5' aria-hidden='true' />
                    <span className='sr-only'>Use grid view</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery */}
          <section className='mt-8 pb-16' aria-labelledby='gallery-heading'>
            <h2 id='gallery-heading' className='sr-only'>
              Wszystkie zdjęcia
            </h2>
            {loading ? (
              <Loader />
            ) : error ? (
              <Alerts attention title={error} />
            ) : (
              <ul
                className={classNames(
                  'grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-8',
                  isShowing ? ' xl:grid-cols-4' : 'xl:grid-cols-5'
                )}
              >
                {graphics.map((file, index) => (
                  <li
                    key={index}
                    className='relative'
                    onClick={() => {
                      isShowing !== true && setIsShowing(true)
                      setCurrentFile(file)
                    }}
                  >
                    <div
                      className={classNames(
                        activeIdx === index
                          ? 'ring-2 ring-offset-2 ring-yellow-500'
                          : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-yellow-500',
                        ' group block w-full aspect-w-10 aspect-h-10 rounded-lg bg-gray-100 overflow-hidden'
                      )}
                      onClick={() => handleActiveOnClick(index)}
                    >
                      <Transition
                        show={true}
                        enter='transition-opacity duration-75'
                        enterFrom='opacity-100'
                        enterTo='opacity-0'
                        leave='transition-opacity duration-150'
                        leaveFrom='opacity-0'
                        leaveTo='opacity-100'
                      >
                        <img
                          src={file?.graphic?.cover || file?.graphic?.path}
                          className={classNames(
                            activeIdx === index ? '' : 'group-hover:opacity-75',
                            ' object-cover pointer-events-none'
                          )}
                          alt={file?.graphic?.name}
                        />
                      </Transition>
                      <button
                        type='button'
                        className='absolute inset-0 focus:outline-none'
                      >
                        <span className='sr-only'>
                          View details for {file?.graphic?.name}
                        </span>
                      </button>
                    </div>
                    <p className='mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none'>
                      {file?.graphic?.name}
                    </p>
                    <p className='block text-sm font-medium text-gray-500 pointer-events-none'>
                      {file?.graphic?.size}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </section>
        </div>
      </main>

      <Transition
        show={isShowing}
        enter='transition-opacity duration-75'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {loadingToggle && <Loader />}
        <aside className='hidden w-96 bg-white p-8 border-l border-gray-200 overflow-y-auto lg:block'>
          <div className='pb-16 space-y-6'>
            <div>
              <div className='block w-full rounded-lg overflow-hidden relative'>
                <button
                  type='button'
                  className='absolute top-0 left-0 bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500'
                  onClick={() => setIsShowing(false)}
                >
                  <ArrowRightIcon className=' h-6 w-6' aria-hidden='true' />
                </button>
                <img
                  src={
                    currentFile?.graphic?.cover || currentFile?.graphic?.path
                  }
                  className='object-cover mx-auto'
                  width='200'
                  height='200'
                  alt={currentFile?.graphic?.name}
                />
              </div>
              <div className='mt-4 flex items-start justify-between'>
                <div>
                  <h2 className='text-lg font-medium text-gray-900'>
                    <span className='sr-only'>Szczegóły dla</span>
                    {currentFile?.graphic?.name}
                  </h2>
                  <p className='text-sm font-medium text-gray-500'>???</p>
                </div>
                <button
                  onClick={() => {
                    isFavoriteHandler(currentFile?._id)
                    setCurrentFile({
                      ...currentFile,
                      isFavorite: !currentFile.isFavorite,
                    })
                  }}
                  type='button'
                  className='ml-4 bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500'
                >
                  {currentFile?.isFavorite ? (
                    <HeartIconSolid className='h-6 w-6' aria-hidden='true' />
                  ) : (
                    <HeartIconOutline className='h-6 w-6' aria-hidden='true' />
                  )}

                  <span className='sr-only'>Ulubione</span>
                </button>
              </div>
            </div>
            <div>
              <h3 className='font-medium text-gray-900'>Informacje</h3>
              <dl className='mt-2 border-t border-b border-gray-200 divide-y divide-gray-200'>
                <div className='py-3 flex justify-between text-sm font-medium'>
                  <dt className='text-gray-500'>Wielkość</dt>
                  <dd className='text-gray-900'>
                    {convertBytes(currentFile?.graphic?.size).toFixed(2) +
                      ' MB'}
                  </dd>
                </div>
                <div className='py-3 flex justify-between text-sm font-medium'>
                  <dt className='text-gray-500'>Format</dt>
                  <dd className='text-gray-900'>
                    {currentFile?.graphic?.type}
                  </dd>
                </div>
              </dl>
            </div>

            <div className='flex text-center'>
              <button
                onClick={() => downloadFile(currentFile)}
                className='flex-1 bg-yellow-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                Pobierz
              </button>
            </div>
          </div>
        </aside>
      </Transition>
    </div>
  )
}

export default LibraryScreen
