export const MACHINES_REQUEST = 'MACHINES_REQUEST'
export const MACHINES_SUCCESS = 'MACHINES_SUCCESS'
export const MACHINES_FAIL = 'MACHINES_FAIL'

export const MACHINE_CREATE_REQUEST = 'MACHINE_CREATE_REQUEST'
export const MACHINE_CREATE_SUCCESS = 'MACHINE_CREATE_SUCCESS'
export const MACHINE_CREATE_FAIL = 'MACHINE_CREATE_FAIL'
export const MACHINE_CREATE_RESET = 'MACHINE_CREATE_RESET'

export const MACHINE_UPDATE_REQUEST = 'MACHINE_UPDATE_REQUEST'
export const MACHINE_UPDATE_SUCCESS = 'MACHINE_UPDATE_SUCCESS'
export const MACHINE_UPDATE_FAIL = 'MACHINE_UPDATE_FAIL'
export const MACHINE_UPDATE_RESET = 'MACHINE_UPDATE_RESET'

export const MACHINE_LIST_REQUEST = 'MACHINE_LIST_REQUEST'
export const MACHINE_LIST_SUCCESS = 'MACHINE_LIST_SUCCESS'
export const MACHINE_LIST_FAIL = 'MACHINE_LIST_FAIL'

export const MACHINE_DETAILS_REQUEST = 'MACHINE_DETAILS_REQUEST'
export const MACHINE_DETAILS_SUCCESS = 'MACHINE_DETAILS_SUCCESS'
export const MACHINE_DETAILS_FAIL = 'MACHINE_DETAILS_FAIL'
