import axios from 'axios'
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { createMachine } from 'state/actions/machineActions'
import Loader from 'common/components/Loader'
import { MACHINE_CREATE_RESET } from 'state/constants/machineConstants'

const AddMachineForm = ({ open, setOpen }) => {
  const [name, setName] = useState('')
  const [user, setUser] = useState('')
  const [image, setImage] = useState('/images/falco.png')
  const [uploading, setUploading] = useState(false)
  const [serialNumber, setSerialNumber] = useState('')
  const [purchaseDate, setPurchaseDate] = useState('')
  const [guarantee, setGuarantee] = useState('')
  const [lastInspectionDate, setLastInspectionDate] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const machineCreate = useSelector((state) => state.machineCreate)
  const { success: successCreate } = machineCreate

  useEffect(() => {
    if (successCreate) {
      dispatch({ type: MACHINE_CREATE_RESET })
      setOpen(false)
    }
  }, [dispatch, successCreate, setOpen])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const createMachineHandler = (e) => {
    e.preventDefault()
    dispatch(
      createMachine({
        name,
        user,
        image,
        serialNumber,
        purchaseDate,
        guarantee,
        lastInspectionDate,
      })
    )
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-50 inset-0 overflow-y-auto'
        //initialFocus={cancelButtonRef}
        open={false}
        onClose={setOpen}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <form className='space-y-6' onSubmit={createMachineHandler}>
                <div>
                  <div>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      Dodaj nową maszynę
                    </Dialog.Title>
                    <Dialog.Description className='mt-3 text-sm text-gray-500'>
                      Formularz umożliwiający dodawanie nowych maszyn.
                    </Dialog.Description>
                  </div>

                  <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                    <div className='sm:col-span-6'>
                      <label
                        htmlFor='name'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Nazwa
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='name'
                          id='name'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-3'>
                      <label
                        htmlFor='serialNumber'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Numer seryjny
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='serialNumber'
                          id='serialNumber'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={serialNumber}
                          onChange={(e) => setSerialNumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-3'>
                      <label
                        htmlFor='serialNumber'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Użytkownik
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='text'
                          name='user'
                          id='user'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-6'>
                      <label className='block text-sm font-medium text-gray-700'>
                        Zdjęcie
                      </label>
                      <div className='mt-1 flex items-center space-x-5'>
                        <img
                          className='h-12 w-12 rounded-full'
                          src={image}
                          alt=''
                        />
                        <label
                          htmlFor='image-file'
                          className='bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                        >
                          <span>Zmień</span>
                          <input
                            id='image-file'
                            name='file-upload'
                            type='file'
                            className='sr-only'
                            onChange={uploadFileHandler}
                          />
                          {uploading && <Loader />}
                        </label>
                      </div>
                    </div>

                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='purchaseDate'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Data zakupu
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='date'
                          name='purchaseDate'
                          id='purchaseDate'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={purchaseDate}
                          onChange={(e) => setPurchaseDate(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='guarantee'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Gwarancja
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='date'
                          name='guarantee'
                          id='guarantee'
                          className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={guarantee}
                          onChange={(e) => setGuarantee(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='lastInspectionDate'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Ostatnia inspekcja
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                          type='date'
                          name='lastInspectionDate'
                          id='lastInspectionDate'
                          className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                          value={lastInspectionDate}
                          onChange={(e) =>
                            setLastInspectionDate(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='pt-5'>
                  <div className='flex justify-end'>
                    <button
                      onClick={() => setOpen(false)}
                      type='button'
                      className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Anuluj
                    </button>
                    <button
                      type='submit'
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Wyślij
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddMachineForm
