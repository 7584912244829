import axios from 'axios'
import { useState } from 'react'
import Alerts from 'common/components/Alerts'

function ResetPasswordScreen() {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      await axios.post(
        'http://127.0.0.1:5000/api/users/reset',
        {
          email,
        },
        config
      )
      setSuccess(true)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img
          className='mx-auto h-12 w-auto'
          src='/images/Logo_MyIMAGO.png'
          alt='Workflow'
        />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Reset hasła
        </h2>
        <p className='mt-2 text-center text-sm text-gray-600'>
          Zapomniałeś hasła? Proszę wprowadzić adres e-mail. W wiadomości email
          wyślemy odnośnik potrzebny do utworzenia nowego hasła.
        </p>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <form onSubmit={submitHandler} className='space-y-6'>
            {success && (
              <Alerts title='Wiadomość wysłana!' check>
                Na twój adres email wysłaliśmy link do ustawienia nowego hasła.
              </Alerts>
            )}
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'
              >
                Adres email
              </label>
              <div className='mt-1'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  required
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                Zresetuj hasło
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordScreen
