import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Alerts from 'common/components/Alerts'
import { addToCart, removeFromCart } from 'state/actions/cartActions'
import { TrashIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id

  const qty = location.search ? Number(location.search.split('=')[1]) : 1

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty))
    }
  }, [dispatch, productId, qty])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    history.push('/wysylka')
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <h1 className='text-2xl font-semibold text-gray-900'>Koszyk</h1>
        </div>
        <div className='mt-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='flex gap-4'>
            <div className='w-3/4'>
              {cartItems.length === 0 ? (
                <Alerts attention title='Pusto!'>
                  Nie masz żadnych produktów w koszyku{' '}
                  <Link to='/'>Powrót</Link>
                </Alerts>
              ) : (
                <div className='flex flex-col'>
                  <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                      <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-200'>
                          <thead className='bg-gray-50'>
                            <tr>
                              <th
                                scope='col'
                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                              >
                                Produkt
                              </th>
                              <th
                                scope='col'
                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                              >
                                Cena
                              </th>
                              <th
                                scope='col'
                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                              >
                                Ilość
                              </th>
                              <th scope='col' className='relative px-6 py-3'>
                                <span className='sr-only'>Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='bg-white divide-y divide-gray-200'>
                            {cartItems.map((item, index) => (
                              <tr key={index}>
                                <td className='px-6 py-4'>
                                  <div className='flex items-center'>
                                    <div className='flex-shrink-0 h-10 w-10'>
                                      <img
                                        className='h-10 w-10 rounded-full'
                                        src={item.image}
                                        alt={item.name}
                                      />
                                    </div>
                                    <div className='ml-4'>
                                      <div className='text-sm font-medium text-gray-900'>
                                        {item.name}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className='px-6 py-4'>
                                  <div className='text-sm text-gray-900'>
                                    {item.price - item.discount}
                                  </div>
                                </td>
                                <td className='px-6 py-4'>
                                  <div>
                                    <select
                                      value={item.qty}
                                      onChange={(e) =>
                                        dispatch(
                                          addToCart(
                                            item.product,
                                            Number(e.target.value)
                                          )
                                        )
                                      }
                                      id='quantity'
                                      name='quantity'
                                      className='mt-1 pl-4 pr-4 py-2 border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm rounded-md'
                                    >
                                      {[...Array(item.countInStock).keys()].map(
                                        (x) => (
                                          <option key={x + 1} value={x + 1}>
                                            {x + 1}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                                  <button
                                    type='button'
                                    onClick={() =>
                                      removeFromCartHandler(item.product)
                                    }
                                    className='inline-flex items-center justify-center text-yellow-600 hover:text-yellow-900'
                                  >
                                    <TrashIcon
                                      className='-ml-1 mr-1 h-4 w-4'
                                      aria-hidden='true'
                                    />
                                    Usuń
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='w-1/4'>
              <div className='p-6 flex flex-col text-center gap-6 bg-white rounded-lg shadow'>
                <h2 className='text-xl font-semibold text-gray-900'>
                  Podsumowanie
                </h2>
                <div>
                  <dl className='mt-2 border-t border-b border-gray-200 divide-y divide-gray-200'>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-500'>Ilość wszystkich</dt>
                      <dd className='text-gray-900'>
                        {cartItems.reduce((acc, item) => acc + item.qty, 0)}
                      </dd>
                    </div>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-500'>Suma</dt>
                      <dd className='text-lg text-gray-900'>
                        {cartItems
                          .reduce(
                            (acc, item) =>
                              acc + item.qty * (item.price - item.discount),
                            0
                          )
                          .toFixed(2)}{' '}
                        zł
                      </dd>
                    </div>
                  </dl>
                </div>
                <button
                  onClick={checkoutHandler}
                  type='button'
                  className='inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                >
                  Przejdź do płatności
                  <ArrowNarrowRightIcon
                    className='ml-3 -mr-1 h-5 w-5'
                    aria-hidden='true'
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default CartScreen
