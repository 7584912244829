import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Heading from 'common/components/Heading'
import Alerts from 'common/components/Alerts'
import Loader from 'common/components/Loader'
import { listMachineDetails, updateMachine } from 'state/actions/machineActions'
import { MACHINE_CREATE_RESET } from 'state/constants/machineConstants'

const EditMachineScreen = ({ match, history }) => {
  const machineID = match.params.id

  const [name, setName] = useState('')
  const [user, setUser] = useState('')
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [serialNumber, setSerialNumber] = useState('')
  const [purchaseDate, setPurchaseDate] = useState('')
  const [guarantee, setGuarantee] = useState('')
  const [lastInspectionDate, setLastInspectionDate] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const machineDetails = useSelector((state) => state.machineDetails)
  const { machine, loading, error } = machineDetails

  const machineUpdate = useSelector((state) => state.machineUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = machineUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: MACHINE_CREATE_RESET })
      history.push('/admin/maszyny')
    } else {
      if (!machine.name || machine._id !== machineID) {
        dispatch(listMachineDetails(machineID))
      } else {
        setName(machine.name)
        setUser(machine.user)
        setImage(machine.image)
        setSerialNumber(machine.serialNumber)
        setPurchaseDate(machine.purchaseDate)
        setGuarantee(machine.guarantee)
        setLastInspectionDate(machine.lastInspectionDate)
      }
    }
  }, [dispatch, history, machineID, machine, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateMachine({
        _id: machineID,
        name,
        user,
        image,
        serialNumber,
        purchaseDate,
        guarantee,
        lastInspectionDate,
      })
    )
  }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Edytuj maszynę</Heading>
          <div className='mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
            <div className='md:grid md:grid-cols-3 md:gap-6'>
              <div className='md:col-span-1'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Informacje
                </h3>
                <p className='mt-1 text-sm text-gray-500'>
                  Formularz umożliwiający zmianę danych konkretnej maszyny.
                </p>
              </div>
              <div className='mt-5 md:mt-0 md:col-span-2'>
                {loadingUpdate && <Loader />}
                {error && <Alerts error>{error}</Alerts>}
                {errorUpdate && <Alerts error>{errorUpdate}</Alerts>}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Alerts attention>Błąd!</Alerts>
                ) : (
                  <form onSubmit={submitHandler}>
                    <div className='grid grid-cols-6 gap-6'>
                      <div className='col-span-6 sm:col-span-3'>
                        <label
                          htmlFor='name'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Nazwa
                        </label>
                        <div className='mt-1'>
                          <input
                            type='text'
                            name='name'
                            id='name'
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            className='focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                      </div>

                      <div className='col-span-6 sm:col-span-3'>
                        <label
                          htmlFor='serial-number'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Numer seryjny
                        </label>
                        <input
                          type='text'
                          name='serial-number'
                          id='serial-number'
                          value={serialNumber || ''}
                          onChange={(e) => setSerialNumber(e.target.value)}
                          className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-3'>
                        <label
                          htmlFor='user'
                          className='block text-sm font-medium text-gray-700'
                        >
                          ID użytkownika
                        </label>
                        <input
                          type='text'
                          name='user'
                          id='user'
                          value={machine.user?._id || ''}
                          onChange={(e) => setUser(e.target.value)}
                          className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-3'>
                        <label
                          htmlFor='email'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Email
                        </label>
                        <input
                          type='text'
                          name='email'
                          id='email'
                          value={machine.user?.email || ''}
                          className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200'
                          disabled
                        />
                      </div>

                      <div className='sm:col-span-2'>
                        <label
                          htmlFor='purchaseDate'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Data zakupu
                        </label>
                        <div className='mt-1 flex rounded-md shadow-sm'>
                          <input
                            type='date'
                            name='purchaseDate'
                            id='purchaseDate'
                            className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                            value={purchaseDate?.slice(0, 10) || ''}
                            onChange={(e) => setPurchaseDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-2'>
                        <label
                          htmlFor='guarantee'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Gwarancja
                        </label>
                        <div className='mt-1 flex rounded-md shadow-sm'>
                          <input
                            type='date'
                            name='guarantee'
                            id='guarantee'
                            className='p-1.5 mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                            value={guarantee?.slice(0, 10) || ''}
                            onChange={(e) => setGuarantee(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-2'>
                        <label
                          htmlFor='lastInspectionDate'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Ostatnia inspekcja
                        </label>
                        <div className='mt-1 flex rounded-md shadow-sm'>
                          <input
                            type='date'
                            name='lastInspectionDate'
                            id='lastInspectionDate'
                            className='mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md'
                            value={lastInspectionDate?.slice(0, 10) || ''}
                            onChange={(e) =>
                              setLastInspectionDate(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-6'>
                        <label className='block text-sm font-medium text-gray-700'>
                          Zdjęcie
                        </label>
                        <div className='mt-1 flex items-center space-x-5'>
                          <img
                            className='h-12 w-12 rounded-full'
                            src={image}
                            alt={image}
                          />
                          <label
                            htmlFor='image-file'
                            className='bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            <span>Zmień</span>
                            <input
                              id='image-file'
                              name='file-upload'
                              type='file'
                              className='sr-only'
                              onChange={uploadFileHandler}
                            />
                          </label>
                        </div>
                      </div>
                      {uploading && <Loader />}

                      <div className='col-span-6 sm:col-span-6'>
                        <div className='flex justify-end'>
                          <button
                            onClick={() => history.push('/admin/maszyny')}
                            type='button'
                            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            Anuluj
                          </button>
                          <button
                            type='submit'
                            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          >
                            Zaktualizuj
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default EditMachineScreen
