import axios from 'axios'
import {
  MACHINES_REQUEST,
  MACHINES_SUCCESS,
  MACHINES_FAIL,
  MACHINE_CREATE_REQUEST,
  MACHINE_CREATE_SUCCESS,
  MACHINE_CREATE_FAIL,
  MACHINE_UPDATE_REQUEST,
  MACHINE_UPDATE_SUCCESS,
  MACHINE_UPDATE_FAIL,
  MACHINE_LIST_REQUEST,
  MACHINE_LIST_SUCCESS,
  MACHINE_LIST_FAIL,
  MACHINE_DETAILS_REQUEST,
  MACHINE_DETAILS_SUCCESS,
  MACHINE_DETAILS_FAIL,
} from '../constants/machineConstants.js'

export const listAllMachines =
  (keyword = '', pageNumber = '', sorting = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MACHINES_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/machines?keyword=${keyword}&pageNumber=${pageNumber}&sorting=${sorting}`,
        config
      )

      dispatch({
        type: MACHINES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MACHINES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createMachine = (machine) => async (dispatch, getState) => {
  try {
    dispatch({ type: MACHINE_CREATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/machines/', machine, config)

    dispatch({
      type: MACHINE_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MACHINE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateMachine = (machine) => async (dispatch, getState) => {
  try {
    dispatch({ type: MACHINE_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/machines/${machine._id}`,
      machine,
      config
    )

    dispatch({
      type: MACHINE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MACHINE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listMachines =
  (keyword = '', sorting = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MACHINE_LIST_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/machines/mymachines?keyword=${keyword}&sorting=${sorting}`,
        config
      )

      dispatch({
        type: MACHINE_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MACHINE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listMachineDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MACHINE_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/machines/mymachines/${id}`, config)

    dispatch({
      type: MACHINE_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MACHINE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
