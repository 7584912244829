import axios from 'axios'
import {
  GRAPHIC_LIST_REQUEST,
  GRAPHIC_LIST_SUCCESS,
  GRAPHIC_LIST_FAIL,
  GRAPHIC_TOGGLE_FAVORITE_REQUEST,
  GRAPHIC_TOGGLE_FAVORITE_SUCCESS,
  GRAPHIC_TOGGLE_FAVORITE_FAIL,
} from '../constants/graphicConstants.js'

export const listGraphics =
  (keyword = '', category = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GRAPHIC_LIST_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/graphics?keyword=${keyword}&category=${category}`,
        config
      )

      dispatch({
        type: GRAPHIC_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GRAPHIC_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const toggleFavoriteGraphic = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GRAPHIC_TOGGLE_FAVORITE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/graphics/${id}`, {}, config)

    dispatch({
      type: GRAPHIC_TOGGLE_FAVORITE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: GRAPHIC_TOGGLE_FAVORITE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
