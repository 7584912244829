import axios from 'axios'
import {
  VIDEO_LIST_REQUEST,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAIL,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_SUCCESS,
  VIDEO_DETAILS_FAIL,
} from '../constants/videoConstants.js'

export const listVideo =
  (keyword = '', category = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: VIDEO_LIST_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/video?keyword=${keyword}&category=${category}`,
        config
      )

      dispatch({
        type: VIDEO_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: VIDEO_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listVideoDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: VIDEO_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/video/${id}`)

    dispatch({
      type: VIDEO_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VIDEO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
