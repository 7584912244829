import React, { useState } from 'react'
import {
  DocumentDuplicateIcon,
  ShoppingCartIcon,
  HomeIcon,
  AdjustmentsIcon,
  FilmIcon,
  //CubeIcon,
  QuestionMarkCircleIcon,
  SupportIcon,
} from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import HelpForm from 'common/components/HelpForm'
import Notifications from 'common/components/Notifications'

const navigation = [
  {
    name: 'Strona główna',
    href: '/',
    icon: HomeIcon,
    current: true,
  },
  {
    name: 'Serwis',
    href: '/serwis/',
    icon: SupportIcon,
    current: false,
  },
  {
    name: 'Sklep',
    href: '/sklep/',
    icon: ShoppingCartIcon,
    current: false,
  },
  {
    name: 'Biblioteka grafik',
    href: '/biblioteka/',
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: 'Filmy',
    href: '/filmy/',
    icon: FilmIcon,
    current: false,
  },
  {
    name: 'Ustawienia',
    href: '/ustawienia/',
    icon: AdjustmentsIcon,
    current: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DesktopSidebar = () => {
  const [open, setOpen] = useState(false)
  const [activeIdx, setActiveIdx] = useState(0)
  const [isShowing, setIsShowing] = useState(false)

  const handleActiveOnClick = (index) => {
    setActiveIdx(index)
  }

  return (
    /* Static sidebar for desktop */
    <div className='hidden md:flex md:flex-shrink-0'>
      <div className='flex flex-col w-64'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto'>
          <div className='flex items-center flex-shrink-0 px-4'>
            <img
              className='h-7 w-auto'
              src='/images/Logo_MyIMAGO.png'
              alt='Workflow'
            />
          </div>
          <div className='mt-5 flex-grow flex flex-col'>
            <nav className='flex-1 px-2 bg-white space-y-1'>
              {navigation.map((item, index) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  onClick={() => handleActiveOnClick(index)}
                  className={classNames(
                    activeIdx === index
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-imago_yellow hover:text-white',
                    ' group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                >
                  <item.icon
                    className={classNames(
                      activeIdx === index
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-white',
                      ' mr-3 h-6 w-6'
                    )}
                    aria-hidden='true'
                  />
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className='px-2'>
            <span
              onClick={() => setOpen(true)}
              className='text-gray-600 hover:bg-imago_yellow hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
            >
              <QuestionMarkCircleIcon
                className='text-gray-400 group-hover:text-white mr-3 h-6 w-6'
                aria-hidden='true'
              />
              Pomoc
            </span>
          </div>
          {open && (
            <HelpForm
              open={open}
              setOpen={setOpen}
              setIsShowing={setIsShowing}
            />
          )}
          {isShowing && (
            <Notifications
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              title={'Wiadomość wysłana!'}
              content={'Wkrótce odezwiemy się do Ciebie!'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DesktopSidebar
