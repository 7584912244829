import * as Carousel from 'pages/HomePage/components/Carousel/index'
import { Link } from 'react-router-dom'

const Promotions = () => {
  return (
    <div className='rounded-lg bg-white shadow relative'>
      <Carousel.Component options={{ autoplay: 3000 }}>
        <Carousel.Slide>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1'>
              <img
                className='max-w-full max-w-sm:md h-full rounded-l-lg'
                src='/images/zestaw-Falco1.jpg'
                alt='magenta'
              />
            </div>
            <div className='flex-1 p-6 text-center my-auto'>
              <h2 className='text-lg font-medium text-gray-900'>Promocje</h2>
              <p className='mt-2 text-sm text-gray-500'>
                Zestaw atramentów spożywczych FALCO 4x100ml - 270 zł - Kupuj
                taniej w zestawie!
              </p>
              <Link
                to={'/produkt/61ae0f2bd877546acb461b52'}
                className='mt-3 inline-block px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                Sprawdź!
              </Link>
            </div>
          </div>
        </Carousel.Slide>
        <Carousel.Slide>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1'>
              <img
                className='max-w-full max-w-sm:md h-full rounded-l-lg'
                src='/images/zestaw-VULPES-1.jpg'
                alt='magenta'
              />
            </div>
            <div className='flex-1 p-6 text-center my-auto'>
              <h2 className='text-lg font-medium text-gray-900'>Promocje</h2>
              <p className='mt-2 text-sm text-gray-500'>
                Zestaw atramentów spożywczych VULPES 6x100ml - 400 zł - Kupuj
                taniej w zestawie!
              </p>
              <Link
                to={'/produkt/61ae0f2bd877546acb461b53'}
                className='mt-3 inline-block px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                Sprawdź!
              </Link>
            </div>
          </div>
        </Carousel.Slide>
        <Carousel.Slide>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1'>
              <img
                className='max-w-full max-w-sm:md h-full rounded-l-lg'
                src='/images/promocja-zestaw-E.jpg'
                alt='magenta'
              />
            </div>
            <div className='flex-1 p-6 text-center my-auto'>
              <h2 className='text-lg font-medium text-gray-900'>Promocje</h2>
              <p className='mt-2 text-sm text-gray-500'>
                Zestaw foremek Motywy Świąteczne. - 410 zł - Kup cały zestaw i
                zapłać mniej!
              </p>
              <Link
                to={'/produkt/61ae0f2bd877546acb461b7a'}
                className='mt-3 inline-block px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                Sprawdź!
              </Link>
            </div>
          </div>
        </Carousel.Slide>
      </Carousel.Component>{' '}
    </div>
  )
}

export default Promotions
