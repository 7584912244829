import {
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Alerts = ({ attention, error, check, information, title, children }) => {
  return (
    <div
      className={classNames(
        attention
          ? 'bg-yellow-100'
          : error
          ? 'bg-red-100'
          : check
          ? 'bg-green-100'
          : information
          ? 'bg-blue-100'
          : '',
        'rounded-md p-4 mb-3'
      )}
    >
      <div className='flex'>
        <div className='flex-shrink-0'>
          {attention ? (
            <ExclamationIcon
              className='h-5 w-5 text-yellow-400'
              aria-hidden='true'
            />
          ) : error ? (
            <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
          ) : check ? (
            <CheckCircleIcon
              className='h-5 w-5 text-green-400'
              aria-hidden='true'
            />
          ) : information ? (
            <InformationCircleIcon
              className='h-5 w-5 text-blue-400'
              aria-hidden='true'
            />
          ) : (
            ''
          )}
        </div>
        <div className='ml-3'>
          <h3
            className={classNames(
              attention ? 'text-yellow-800' : '',
              error ? 'text-red-800' : '',
              check ? 'text-green-800' : '',
              information ? 'text-blue-800' : '',
              'text-sm font-medium text-yellow-800'
            )}
          >
            {title}
          </h3>
          <div
            className={classNames(
              attention ? 'text-yellow-700' : '',
              error ? 'text-red-700' : '',
              check ? 'text-green-700' : '',
              information ? 'text-blue-700' : '',
              'mt-2 text-sm text-yellow-700}'
            )}
          >
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Alerts
