import {
  MACHINE_LIST_REQUEST,
  MACHINE_LIST_SUCCESS,
  MACHINE_LIST_FAIL,
  MACHINE_DETAILS_SUCCESS,
  MACHINE_DETAILS_REQUEST,
  MACHINE_DETAILS_FAIL,
  MACHINES_REQUEST,
  MACHINES_SUCCESS,
  MACHINES_FAIL,
  MACHINE_CREATE_RESET,
  MACHINE_CREATE_REQUEST,
  MACHINE_CREATE_SUCCESS,
  MACHINE_CREATE_FAIL,
  MACHINE_UPDATE_FAIL,
  MACHINE_UPDATE_RESET,
  MACHINE_UPDATE_SUCCESS,
  MACHINE_UPDATE_REQUEST,
} from '../constants/machineConstants'

export const machinesAllReducer = (state = { machines: [] }, action) => {
  switch (action.type) {
    case MACHINES_REQUEST:
      return {
        loading: true,
        machines: [],
      }
    case MACHINES_SUCCESS:
      return {
        loading: false,
        machines: action.payload.machines,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case MACHINES_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const machineCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MACHINE_CREATE_REQUEST:
      return {
        loading: true,
      }
    case MACHINE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        machine: action.payload,
      }
    case MACHINE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case MACHINE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const machineUpdateReducer = (state = { machine: {} }, action) => {
  switch (action.type) {
    case MACHINE_UPDATE_REQUEST:
      return {
        loading: true,
      }
    case MACHINE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        machine: action.payload,
      }
    case MACHINE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case MACHINE_UPDATE_RESET:
      return { machine: {} }
    default:
      return state
  }
}

export const machineListReducer = (state = { machines: [] }, action) => {
  switch (action.type) {
    case MACHINE_LIST_REQUEST:
      return {
        loading: true,
        machines: [],
      }
    case MACHINE_LIST_SUCCESS:
      return {
        loading: false,
        machines: action.payload,
      }
    case MACHINE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const machineDetailsReducer = (state = { machine: {} }, action) => {
  switch (action.type) {
    case MACHINE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case MACHINE_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        machine: action.payload,
      }
    case MACHINE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
