import { BellIcon } from '@heroicons/react/outline'

const NotificationButton = () => {
  return (
    <button className='bg-white p-1 rounded-full text-yellow-500 hover:text-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'>
      <span className='sr-only'>Zobacz powiadomienia</span>
      <BellIcon className='h-6 w-6' aria-hidden='true' />
    </button>
  )
}

export default NotificationButton
