import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useHistory } from 'react-router-dom'
import { XIcon, ShoppingBagIcon, TrashIcon } from '@heroicons/react/outline'
import { useSelector, useDispatch } from 'react-redux'
import Alerts from 'common/components/Alerts'
import { removeFromCart, addToCart } from 'state/actions/cartActions'

const CartSidebar = () => {
  let history = useHistory()

  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    history.push('/wysylka')
    setOpen(false)
  }

  const goToCartHandler = () => {
    history.push('/koszyk')
    setOpen(false)
  }

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className='relative bg-white p-1 rounded-full text-yellow-500 hover:text-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
      >
        <span className='sr-only'>Zobacz koszyk</span>
        <ShoppingBagIcon className='h-6 w-6' aria-hidden='true' />
        {cartItems.length > 0 && (
          <span
            style={{ fontSize: '9px', borderRadius: '50px' }}
            className='absolute bottom-0 rigth-0 px-1 py-0.5 inline-flex items-center font-medium bg-yellow-500 text-white'
          >
            {cartItems.reduce((acc, item) => acc + item.qty, 0)}
          </span>
        )}
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          static
          className='z-20 fixed inset-0 overflow-hidden'
          open={open}
          onClose={setOpen}
        >
          <div className='absolute inset-0 overflow-hidden'>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>
            <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <div className='relative w-screen max-w-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4'>
                      <button
                        className='rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                        onClick={() => setOpen(false)}
                      >
                        <span className='sr-only'>Zamknij koszyk</span>
                        <XIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll'>
                    <div className='px-4 sm:px-6'>
                      <Dialog.Title className='text-xl font-medium text-gray-900'>
                        Podsumowanie
                      </Dialog.Title>
                    </div>
                    <div className='mt-6 relative flex-1 px-4 sm:px-6'>
                      {cart.cartItems.length === 0 ? (
                        <Alerts attention title='Pusty!'>
                          Jeszcze nic tutaj nie ma
                        </Alerts>
                      ) : (
                        <div>
                          <ul className='divide-y divide-gray-200'>
                            {cartItems.map((item) => (
                              <li key={item.name} className='py-4'>
                                <div className='flex space-x-3'>
                                  <img
                                    className='h-10 w-10 rounded-full'
                                    src={item.image}
                                    alt={item.name}
                                  />
                                  <div className='flex-1 space-y-1'>
                                    <div className='flex items-center justify-between'>
                                      <a href={`/produkt/${item.product}`}>
                                        <h3 className='mr-0.5 text-sm font-medium hover:text-gray-800'>
                                          {item.name}
                                        </h3>
                                      </a>
                                      <select
                                        value={item.qty}
                                        onChange={(e) =>
                                          dispatch(
                                            addToCart(
                                              item.product,
                                              Number(e.target.value)
                                            )
                                          )
                                        }
                                        id='quantity'
                                        name='quantity'
                                        className='mr-3 mt-1 pl-4 pr-4 py-2 border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm rounded-md'
                                      >
                                        {[
                                          ...Array(item.countInStock).keys(),
                                        ].map((x) => (
                                          <option key={x + 1} value={x + 1}>
                                            {x + 1}
                                          </option>
                                        ))}
                                      </select>
                                      <button
                                        type='button'
                                        onClick={() =>
                                          removeFromCartHandler(item.product)
                                        }
                                        className='text-sm inline-flex items-center justify-center text-yellow-600 hover:text-yellow-500'
                                      >
                                        <TrashIcon
                                          className='-ml-1 mr-1 h-4 w-4'
                                          aria-hidden='true'
                                        />
                                        Usuń
                                      </button>
                                    </div>
                                    <p className='text-sm text-gray-500'>
                                      {item.price - item.discount} x {item.qty}{' '}
                                      ={' '}
                                      {item.qty * (item.price - item.discount)}{' '}
                                      zł
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div className='mt-3 border-t border-gray-200'>
                            <div className='py-6 flex items-center font-medium'>
                              <p className='text-gray-500 text-sm'>Łącznie:</p>
                              <p className='ml-3 text-gray-900 text-base'>
                                {cartItems
                                  .reduce(
                                    (acc, item) =>
                                      acc +
                                      item.qty * (item.price - item.discount),
                                    0
                                  )
                                  .toFixed(2)}{' '}
                                zł
                              </p>
                              {console.log(cartItems)}
                            </div>
                          </div>
                          <div className='flex'>
                            <button
                              onClick={goToCartHandler}
                              type='button'
                              className='w-full bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                            >
                              Zobacz koszyk
                            </button>
                            <button
                              onClick={checkoutHandler}
                              type='button'
                              className='w-full ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                            >
                              Zamówienie
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default CartSidebar
