import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails } from 'state/actions/userActions'
import { listMyOrders } from 'state/actions/orderActions'
import Loader from 'common/components/Loader'
import Alerts from 'common/components/Alerts'

const OrdersScreen = () => {
  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  const orderList = useSelector((state) => state.orderList)
  const { loading: loadingOrders, error: errorOrders, orders } = orderList

  useEffect(() => {
    if (!user || !user.name) {
      dispatch(getUserDetails('profile'))
      dispatch(listMyOrders())
    }
  }, [dispatch, user])

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <h1 className='text-2xl font-semibold text-gray-900'>
            Lista zamówień
          </h1>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='my-6 flex flex-col'>
            <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                {loadingOrders ? (
                  <Loader />
                ) : errorOrders ? (
                  <Alerts>{errorOrders}</Alerts>
                ) : (
                  <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Numer zamówienia
                          </th>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Data zamówienia
                          </th>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Status płatności
                          </th>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Status wysyłki
                          </th>
                          <th scope='col' className='relative px-6 py-3'>
                            <p className='sr-only'>Edit</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {orders.map((order) => (
                          <tr key={order._id}>
                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800'>
                              {order._id}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500'>
                              {order.orderDate.substring(0, 10)}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500'>
                              {order.isPaid
                                ? 'zapłacone: ' + order.paidAt.substring(0, 10)
                                : 'x'}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500'>
                              {order.isShipped
                                ? 'wysłane: ' +
                                  order?.shippedAt.substring(0, 10)
                                : 'x'}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                              <Link
                                to={`/zamowienie/${order._id}`}
                                className='text-yellow-600 hover:text-yellow-900'
                              >
                                Szczegóły
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default OrdersScreen
