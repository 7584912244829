import { useState, useEffect } from 'react'
import { PaperClipIcon } from '@heroicons/react/solid'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'common/components/Loader'
import Alerts from 'common/components/Alerts'
import Heading from 'common/components/Heading'
import Subheading from 'common/components/Subheading'
import Product from 'pages/ShopPage/components/Product'
import { ShoppingCartIcon } from '@heroicons/react/outline'
import {
  listProducts,
  listProductDetails,
  relatedProducts,
} from 'state/actions/productActions'

const ProductScreen = ({
  history,
  match,
  _id,
  name,
  image,
  title,
  discount,
}) => {
  const [qty, setQty] = useState(1)

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productid = product._id

  const [categoryProduct, setCategoryProduct] = useState(product.category)

  const productRelated = useSelector((state) => state.productRelated)
  const { products: relatedProductsList } = productRelated

  useEffect(() => {
    dispatch(listProductDetails(match.params.id))
    dispatch(listProducts())
    setCategoryProduct(product.category)
    dispatch(relatedProducts(categoryProduct, productid))
  }, [dispatch, match, categoryProduct, product.category, productid])

  const addToCartHandler = () => {
    history.push(`/koszyk/${match.params.id}?qty=${qty}`)
  }

  const downloadFile = (file) => {
    fetch('https://myimago.pl' + file.url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.name)

        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      })
  }

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Produkt</Heading>
          {loading ? (
            <Loader />
          ) : error ? (
            <Alerts error title='Błąd!'>
              {error}
            </Alerts>
          ) : (
            <div>
              <div className='flex flex-row lg:gap-6 my-6'>
                <div className='flex-initial max-w-3/5'>
                  <img
                    className='max-w-xl'
                    src={product.image}
                    alt={product.name}
                  />
                </div>
                <div className='flex-initial p-8 flex flex-col text-center gap-6 bg-white rounded-lg shadow'>
                  <h1 className='text-2xl font-semibold text-gray-900'>
                    {product.name}
                  </h1>
                  <h2 className='text-xl text-gray-600'>
                    {product.price - product.discount} zł
                  </h2>
                  {product.category !== 'grafiki' &&
                    product.category !== 'akcesoria cukiernicze' && (
                      <div>
                        <label
                          htmlFor='quantity'
                          className='block text-base font-medium text-gray-700'
                        >
                          Ilość
                        </label>
                        <select
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                          id='quantity'
                          name='quantity'
                          className='mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md'
                        >
                          {[...Array(product.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  <button
                    onClick={addToCartHandler}
                    type='button'
                    className='mx-auto inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                  >
                    Dodaj do koszyka
                    <ShoppingCartIcon
                      className='ml-1 mr-3 h-5 w-5'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </div>
              <div className='bg-white shadow overflow-hidden sm:rounded-lg'>
                <div className='px-4 py-5 sm:px-6'>
                  <h3 className='text-lg leading-6 font-medium text-gray-900'>
                    Informacje o produkcie
                  </h3>
                </div>
                <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
                  <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                    {product.quantity !== undefined && (
                      <div className='sm:col-span-1'>
                        <dt className='text-sm font-medium text-gray-500'>
                          Ilość
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900'>
                          {product.quantity}
                        </dd>
                      </div>
                    )}
                    {product.capacity !== false &&
                      product.category !== 'grafiki' &&
                      product.category !== 'akcesoria cukiernicze' && (
                        <div className='sm:col-span-1'>
                          <dt className='text-sm font-medium text-gray-500'>
                            Pojemność
                          </dt>
                          <dd className='mt-1 text-sm text-gray-900'>
                            {product.capacity + 'ml'}
                          </dd>
                        </div>
                      )}
                    <div className='sm:col-span-2'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Opis
                      </dt>
                      <dd className='mt-1 text-sm text-gray-900'>
                        {product.description}
                      </dd>
                    </div>
                    {product.files !== undefined && product.files.length > 0 && (
                      <div className='sm:col-span-2'>
                        <dt className='text-sm font-medium text-gray-500'>
                          Pliki
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900'>
                          <ul className='border border-gray-200 rounded-md divide-y divide-gray-200'>
                            {product.files.map((file) => (
                              <li
                                key={file.name}
                                className='pl-3 pr-4 py-3 flex items-center justify-between text-sm'
                              >
                                <div className='w-0 flex-1 flex items-center'>
                                  <PaperClipIcon
                                    className='flex-shrink-0 h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                  <span className='ml-2 flex-1 w-0 truncate'>
                                    {file.name}
                                  </span>
                                </div>
                                <div className='ml-4 flex-shrink-0'>
                                  <button
                                    onClick={() => downloadFile(file)}
                                    className='font-medium text-yellow-600 hover:text-yellow-500'
                                  >
                                    Pobierz
                                  </button>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
              <Subheading>Powiązane produkty</Subheading>
              <ul className='pb-6 grid grid-cols-1 md:grid-cols-4 gap-6'>
                {relatedProductsList?.map((product, index) => (
                  <Product key={index} {...product} />
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export default ProductScreen
