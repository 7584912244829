import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  DocumentDuplicateIcon,
  SupportIcon,
  ShoppingCartIcon,
  HomeIcon,
  //AdjustmentsIcon,
  CogIcon,
  FilmIcon,
  XIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import HelpForm from 'common/components/HelpForm'
import Notifications from 'common/components/Notifications'

const navigation = [
  { name: 'Strona główna', href: '/', icon: HomeIcon, current: true },
  { name: 'Serwis', href: '/serwis/', icon: SupportIcon, current: false },
  { name: 'Sklep', href: '/sklep/', icon: ShoppingCartIcon, current: false },
  {
    name: 'Biblioteka grafik',
    href: '/biblioteka/',
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: 'Filmy',
    href: '/filmy/',
    icon: FilmIcon,
    current: false,
  },
  {
    name: 'Ustawienia',
    href: '/ustawienia/',
    icon: CogIcon,
    current: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MobileNav = ({ sidebarOpen, setSidebarOpen }) => {
  const [open, setOpen] = useState(false)
  const [activeIdx, setActiveIdx] = useState(0)
  const [isShowing, setIsShowing] = useState(false)

  const handleActiveOnClick = (index) => {
    setActiveIdx(index)
  }

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed inset-0 flex z-40 md:hidden'
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter='transition ease-in-out duration-300 transform'
          enterFrom='-translate-x-full'
          enterTo='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leaveFrom='translate-x-0'
          leaveTo='-translate-x-full'
        >
          <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='absolute top-0 right-0 -mr-12 pt-2'>
                <button
                  className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className='sr-only'>Close sidebar</span>
                  <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                </button>
              </div>
            </Transition.Child>
            <div className='flex-shrink-0 flex items-center px-4'>
              <img
                className='h-8 w-auto'
                src='/images/Logo_MyIMAGO.png'
                alt='Workflow'
              />
            </div>
            <div className='mt-5 flex-1 h-0 overflow-y-auto'>
              <nav className='px-2 space-y-1'>
                {navigation.map((item, index) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={() => handleActiveOnClick(index)}
                    className={classNames(
                      activeIdx === index
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-imago_yellow hover:text-white',
                      ' group flex items-center px-2 py-2 text-base font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        activeIdx === index
                          ? 'text-gray-500'
                          : 'text-gray-400 group-hover:text-white',
                        ' mr-4 h-6 w-6'
                      )}
                      aria-hidden='true'
                    />
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
            <div className='px-2'>
              <span
                onClick={() => setOpen(true)}
                className='px-2 text-gray-600 hover:bg-imago_yellow hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
              >
                <QuestionMarkCircleIcon
                  className='text-gray-400 group-hover:text-white mr-3 h-6 w-6'
                  aria-hidden='true'
                />
                Pomoc
              </span>
            </div>
            {open && (
              <HelpForm
                open={open}
                setOpen={setOpen}
                setIsShowing={setIsShowing}
              />
            )}
            {isShowing && (
              <Notifications
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                title={'Wiadomość wysłana!'}
                content={'Wkrótce odezwiemy się do Ciebie!'}
              />
            )}
          </div>
        </Transition.Child>
        <div className='flex-shrink-0 w-14' aria-hidden='true'>
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default MobileNav
