import Glide from '@glidejs/glide'
import { useImperativeHandle, useEffect, useRef, forwardRef } from 'react'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
import '@glidejs/glide/dist/css/glide.core.css'

export const Carousel = forwardRef(({ options, children }, ref) => {
  const sliderRef = useRef()

  useImperativeHandle(ref, () => sliderRef.current)

  useEffect(() => {
    const slider = new Glide(sliderRef.current, options)

    slider.mount({ Controls, Breakpoints })

    return () => slider.destroy()
  }, [options])

  return (
    <div className='glide relative' ref={sliderRef}>
      <div className='glide__track' data-glide-el='track'>
        <ul className='glide__slides'>{children}</ul>
      </div>
      <div
        className='glide__bullets absolute left-2/4 -bottom-4.5'
        data-glide-el='controls[nav]'
      >
        <button className='glide__bullet' data-glide-dir='=0'></button>
        <button className='glide__bullet' data-glide-dir='=1'></button>
        <button className='glide__bullet' data-glide-dir='=2'></button>
      </div>
    </div>
  )
})

export const Slide = forwardRef(({ children }, ref) => {
  return (
    <li className='glide__slide' ref={ref}>
      {children}
    </li>
  )
})
