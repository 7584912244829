import {
  GRAPHIC_LIST_REQUEST,
  GRAPHIC_LIST_SUCCESS,
  GRAPHIC_LIST_FAIL,
  GRAPHIC_TOGGLE_FAVORITE_REQUEST,
  GRAPHIC_TOGGLE_FAVORITE_SUCCESS,
  GRAPHIC_TOGGLE_FAVORITE_FAIL,
} from '../constants/graphicConstants'

export const graphicListReducer = (state = { graphics: [] }, action) => {
  switch (action.type) {
    case GRAPHIC_LIST_REQUEST:
      return {
        loading: true,
        graphics: [],
      }
    case GRAPHIC_LIST_SUCCESS:
      return {
        loading: false,
        graphics: action.payload,
      }
    case GRAPHIC_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const graphicToggleFavoriteReducer = (state = {}, action) => {
  switch (action.type) {
    case GRAPHIC_TOGGLE_FAVORITE_REQUEST:
      return {
        loading: true,
      }
    case GRAPHIC_TOGGLE_FAVORITE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case GRAPHIC_TOGGLE_FAVORITE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
