import { useState } from 'react'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import { Route } from 'react-router-dom'
import DesktopSidebar from './common/components/DesktopSidebar'
import HomeScreen from './pages/HomePage/HomeScreen'
import ServiceScreen from './pages/ServicePage/ServiceScreen'
import LibraryScreen from './pages/LibraryPage/LibraryScreen'
import MoviesScreen from './pages/MoviesPage/MoviesScreen'
import ConfiguratorScreen from './pages/ConfiguratorPage/ConfiguratorScreen'
import SettingsScreen from './pages/SettingsPage/SettingsScreen'
import MachineScreen from './pages/MachinePage/MachineScreen'
import ProfileDropdown from './common/components/ProfileDropdown'
import NotificationButton from './common/components/NotificationButton'
import MobileNav from './common/components/MobileNav'
import LoginScreen from './common/screens/LoginScreen'
import ShopScreen from './pages/ShopPage/ShopScreen'
import ProductScreen from './common/screens/ProductScreen'
import CartSidebar from './common/components/CartSidebar'
import CartScreen from './common/screens/CartScreen'
import ShippingScreen from './common/screens/ShippingScreen'
import PaymentScreen from './common/screens/PaymentScreen'
import PlaceOrderScreen from './common/screens/PlaceOrderScreen'
import OrderScreen from './common/screens/OrderScreen'
import OrdersScreen from './common/screens/OrdersScreen'
import AllOrdersScreen from './common/screens/AllOrdersScreen'
import AllMachinesScreen from './common/screens/AllMachinesScreen'
import AllProductsScreen from './common/screens/AllProductsScreen'
import CategoriesPage from './pages/ShopPage/CategoriesPage'
import EditMachineScreen from './common/screens/EditMachineScreen'
import EditProductScreen from './common/screens/EditProductScreen'
import ResetPasswordScreen from './common/screens/ResetPasswordScreen'
import NewPasswordScreen from './common/screens/NewPasswordScreen'
import { useSelector } from 'react-redux'

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  if (!userInfo) {
    return (
      <>
        <Route exact path='/' component={LoginScreen} />
        <Route exact path='/login' component={LoginScreen} />
        <Route exact path='/reset' component={ResetPasswordScreen} />
        <Route exact path='/reset/:token' component={NewPasswordScreen} />
      </>
    )
  }

  return (
    <div className='h-screen flex overflow-hidden bg-gray-100'>
      <MobileNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <DesktopSidebar />
      <div className='flex flex-col w-0 flex-1 overflow-hidden'>
        <div className='relative z-10 flex-shrink-0 flex h-16 bg-white shadow'>
          <button
            className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500 md:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
          </button>
          <div className='flex-1 px-4 flex justify-end'>
            {/* <Route render={({ history }) => <Search history={history} />} /> */}
            <div className='ml-4 flex items-center md:ml-6'>
              <CartSidebar />
              <NotificationButton />
              <ProfileDropdown />
            </div>
          </div>
        </div>

        <Route path='/' component={HomeScreen} exact />
        <Route path='/serwis/' component={ServiceScreen} exact />
        <Route path='/sklep/' component={CategoriesPage} exact />
        <Route path='/sklep/:category' component={ShopScreen} exact />
        <Route path='/wysylka' component={ShippingScreen} exact />
        <Route path='/platnosc' component={PaymentScreen} exact />
        <Route path='/zamowienie' component={PlaceOrderScreen} exact />
        <Route path='/zamowienie/:id' component={OrderScreen} exact />
        <Route path='/produkt/:id' component={ProductScreen} exact />
        <Route path='/koszyk' component={CartScreen} exact />
        <Route path='/koszyk/:id' component={CartScreen} exact />
        <Route path='/zamowienia' component={OrdersScreen} exact />
        <Route path='/biblioteka/' component={LibraryScreen} exact />
        <Route path='/filmy' component={MoviesScreen} exact />
        <Route path='/konfigurator/' component={ConfiguratorScreen} exact />
        <Route path='/ustawienia/' component={SettingsScreen} exact />
        <Route path='/serwis/maszyna/:id' component={MachineScreen} exact />
        <Route path='/admin/zamowienia' component={AllOrdersScreen} exact />
        <Route path='/admin/maszyny' component={AllMachinesScreen} exact />
        <Route path='/admin/maszyna/:id/edycja' component={EditMachineScreen} />
        <Route path='/admin/produkty/' component={AllProductsScreen} />
        <Route path='/admin/produkt/:id/edycja' component={EditProductScreen} />
        <Route path='/szukaj/:keyword' component={ShopScreen} />
      </div>
    </div>
  )
}
