import Subheading from 'common/components/Subheading'

const posts = [
  {
    name: 'Foremki do pierników, ciastek i topperów – personalizowane akcesoria',
    title:
      'Foremki do pierników i ciastek, określane inaczej jako wykrojniki, to akcesoria cukiernicze, które cechują się wielością zastosowań. Możesz je wykorzystać podczas ...',
    imageUrl: '/images/blog1.png',
    url: 'https://www.imagoprinter.com/foremki-do-piernikow/',
  },
  {
    name: 'Opakowania cukiernicze, czyli jak zrobić wyjątkowe personalizowane prezenty?',
    title:
      'Chcesz wprowadzić do oferty Twojej cukierni słodkie upominki? Obawiasz się wysokich kosztów i tak właściwie nie wiesz, od czego zacząć? Jeżeli odpowiedź na te pytania to “tak”, wykorzystaj...',
    imageUrl: '/images/blog2.png',
    url: 'https://www.imagoprinter.com/opakowania-cukiernicze/',
  },
]

const Articles = () => {
  return (
    <>
      <Subheading>Blog i artykuły</Subheading>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        {posts.map((post) => (
          <article
            key={post.name}
            className='flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'
          >
            <a href={post.url}>
              <img
                className='rounded-t-lg'
                src={post.imageUrl}
                alt={post.title}
              />
            </a>
            <div className='flex flex-col p-5'>
              <h3 className='text-gray-900 text-base font-medium'>
                {post.name}
              </h3>
              <dl className='my-2 flex flex-col justify-between'>
                <dt className='sr-only'>Title</dt>
                <dd className='text-gray-500 text-sm'>{post.title}</dd>
              </dl>
              <a
                href={post.url}
                className='mt-1 md:mt-0 inline-flex justify-center items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                Czytaj dalej
              </a>
            </div>
          </article>
        ))}
      </div>
    </>
  )
}

export default Articles
