import Heading from 'common/components/Heading'
import QuickAction from 'pages/HomePage/components/QuickActions'
import Articles from 'pages/HomePage/components/Articles'
import OrderStatus from 'pages/HomePage/components/OrderStatus'
import Promotions from 'pages/HomePage/components/Promotions'
import NewProducts from 'pages/HomePage/components/NewProducts'

function HomeScreen() {
  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Strona główna</Heading>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3'>
            {/* Left column */}
            <div className='lg:col-start-1 lg:col-span-2'>
              <Promotions />
              <QuickAction />
              <Articles />
            </div>

            {/* Right column */}
            <div>
              <OrderStatus />
              <NewProducts />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default HomeScreen
