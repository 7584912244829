import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listMachines } from 'state/actions/machineActions'
import Subheading from 'common/components/Subheading'
import ReportServiceForm from 'common/components/ReportServiceForm'
import Notifications from 'common/components/Notifications'

const actions = [
  {
    title: 'Zgłoś serwis',
    href: '#',
    image: '/images/SERWIS.png',
  },
  {
    title: 'Kup atrament',
    href: 'sklep/atramenty',
    image: '/images/ATRAMENTY.png',
  },
  {
    title: 'Moje ulubione grafiki',
    href: 'biblioteka',
    image: '/images/MOJE-ULUBIONE-GRAFIKI.png',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function QuickActions() {
  const [open, setOpen] = useState(false)
  const [isShowing, setIsShowing] = useState(false)

  const dispatch = useDispatch()

  const machineList = useSelector((state) => state.machineList)
  const { machines } = machineList

  useEffect(() => {
    dispatch(listMachines())
  }, [dispatch])

  return (
    <>
      <Subheading>Szybkie akcje</Subheading>
      <div className='rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px'>
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0
                ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                : '',
              actionIdx === actions.length - 1
                ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-yellow-500'
            )}
          >
            <div>
              <img
                className='h-14 w-14'
                src={action.image}
                alt={action.title}
              />
            </div>
            <div className='mt-8'>
              <h3 className='text-lg font-medium text-gray-800'>
                {actionIdx === 0 ? (
                  <span className='focus:outline-none'>
                    <span
                      onClick={() => setOpen(true)}
                      className='absolute inset-0 cursor-pointer'
                      aria-hidden='true'
                    />
                    {action.title}
                  </span>
                ) : actionIdx === 1 ? (
                  <a href={action.href} className='focus:outline-none'>
                    <span
                      className='absolute inset-0 cursor-pointer'
                      aria-hidden='true'
                    />
                    {action.title}
                  </a>
                ) : (
                  <Link
                    to={{
                      pathname: action.href,
                      state: {
                        category: 'ulubione',
                        activeTab: 2,
                      },
                    }}
                    className='focus:outline-none'
                  >
                    {/* Extend touch target to entire panel */}
                    <span className='absolute inset-0' aria-hidden='true' />
                    {action.title}
                  </Link>
                )}
              </h3>
            </div>
            <span
              className='pointer-events-none absolute top-6 right-6 text-yellow-500 group-hover:text-yellow-400'
              aria-hidden='true'
            >
              <svg
                className='h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 24 24'
              >
                <path d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
              </svg>
            </span>
          </div>
        ))}
      </div>
      {open && (
        <ReportServiceForm
          open={open}
          setOpen={setOpen}
          setIsShowing={setIsShowing}
          defaultMachine={machines[0].serialNumber}
          machines={machines}
        />
      )}
      {isShowing && (
        <Notifications
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          title={'Wiadomość wysłana!'}
          content={'Wkrótce odezwiemy się do Ciebie!'}
        />
      )}
    </>
  )
}
