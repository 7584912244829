import { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  SortAscendingIcon,
  ChevronDownIcon,
  SearchIcon,
} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import { listMachines } from 'state/actions/machineActions'
import Heading from 'common/components/Heading'
import Loader from 'common/components/Loader'
import Alerts from 'common/components/Alerts'
import StackedList from 'common/components/StackedList'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Service = () => {
  const [keyword, setKeyword] = useState('')
  const [sorting, setSorting] = useState('')

  const dispatch = useDispatch()

  const machineList = useSelector((state) => state.machineList)
  const { loading, machines, error } = machineList

  useEffect(() => {
    dispatch(listMachines(keyword, sorting))
  }, [dispatch, keyword, sorting])

  return (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <Heading>Serwis</Heading>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-2 pb-5 sm:flex sm:items-center sm:justify-between'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Lista maszyn
            </h3>
            <div className='mt-3 sm:mt-0 sm:ml-4'>
              <label htmlFor='search-machine' className='sr-only'>
                Szukaj maszyny
              </label>
              <div className='flex rounded-md shadow-sm'>
                <div className='relative flex-grow focus-within:z-10'>
                  <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                    <SearchIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                  <input
                    type='text'
                    name='search-machine'
                    id='search-machine'
                    className='focus:ring-yellow-500 focus:border-yellow-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300'
                    placeholder='Szukaj'
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <input
                    type='text'
                    name='search-machine'
                    id='search-machine'
                    className='p-2.5 hidden focus:ring-yellow-500 focus:border-yellow-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300'
                    placeholder='Szukaj maszyny'
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
                <Menu as='div' className='relative inline-block text-left'>
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className='-ml-px relative inline-flex items-center px-4 py-2.5 border border-yellow-500 text-sm font-medium rounded-r-md text-white bg-imago_yellow hover:bg-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500'>
                          <SortAscendingIcon
                            className='h-5 w-5 text-white'
                            aria-hidden='true'
                          />
                          <span className='ml-2'>Sortuj</span>
                          <ChevronDownIcon
                            className='ml-2.5 -mr-1.5 h-5 w-5 text-white'
                            aria-hidden='true'
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items
                          static
                          className='z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                onClick={() => setSorting(1)}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  ' block px-4 py-2 text-sm'
                                )}
                              >
                                malejąco wg. daty gwarancji
                              </span>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                onClick={() => setSorting(-1)}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  ' block px-4 py-2 text-sm'
                                )}
                              >
                                wzrastająco wg. daty gwarancji
                              </span>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : error ? (
            <Alerts>{error}</Alerts>
          ) : (
            <StackedList data={machines} />
          )}
        </div>
      </div>
    </main>
  )
}

export default Service
