function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Pagination = ({ pages, page, setPageNumber }) => {
  return (
    pages > 1 && (
      <div className='mt-6 w-full flex justify-center'>
        <nav
          className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
          aria-label='Pagination'
        >
          {[...Array(pages).keys()].map((x, index) => (
            <button
              type='button'
              className={classNames(
                x + 1 === page
                  ? 'bg-imago_yellow border-imago_yellow text-white'
                  : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                ' relative inline-flex items-center px-4 py-2 border text-sm font-medium'
              )}
              key={x + 1}
              onClick={() => {
                setPageNumber(x + 1)
              }}
            >
              <span active={(x + 1 === page).toString()}>{x + 1}</span>
            </button>
          ))}
        </nav>
      </div>
    )
  )
}

export default Pagination
