import axios from 'axios'
import { useState, useEffect } from 'react'
//import { PayPalButton } from 'react-paypal-button-v2'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Alerts from 'common/components/Alerts'
import Loader from 'common/components/Loader'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from 'state/actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from 'state/constants/orderConstants'

const OrderScreen = ({ match }) => {
  const orderId = match.params.id

  const [sdkReady, setSdkReady] = useState(false)

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Przeliczenie ostatecznej ceny
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  if (!loading) {
    order.itemsPrice = addDecimals(
      order.orderItems.reduce(
        (acc, item) => acc + (item.price - item.discount) * item.qty,
        0
      )
    )
  }

  useEffect(() => {
    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get('/api/config/paypal')
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
      script.async = true
      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch(getOrderDetails(orderId))
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript()
      } else {
        setSdkReady(true)
      }
    }
  }, [dispatch, successPay, successDeliver, orderId, order])

  const successPaymentHandler = () => {
    dispatch(payOrder(orderId))
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Alerts error title={error} />
  ) : (
    <main
      className='flex-1 relative overflow-y-auto focus:outline-none'
      tabIndex='0'
    >
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <h1 className='text-2xl font-semibold text-gray-900'>Zamówienie</h1>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mt-6 flex flex-col lg:flex-row gap-4 relative'>
            <div className='w-full lg:w-3/5 bg-white shadow overflow-hidden sm:rounded-lg sm:overflow-hidden'>
              <div className='px-4 py-5 sm:px-6'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  ID zamówienia: {order._id}
                </h3>
                <p className='mt-1 max-w-2xl text-sm text-gray-500'></p>
              </div>
              <div className='border-t border-gray-200'>
                <dl>
                  <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Adres do wysyłki
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {order.shippingAddress.address},<br />
                      {order.shippingAddress.city}{' '}
                      {order.shippingAddress.postalCode},{' '}
                      {order.shippingAddress.country}
                      <br />
                      {order.isShipped ? (
                        <Alerts check title='Wysłane!'>
                          {order.shippedAt}
                        </Alerts>
                      ) : (
                        <Alerts error title='Nie wysłane!' />
                      )}
                    </dd>
                  </div>
                  <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Płatność
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {order.paymentMethod}
                      <br />
                      {order.isPaid ? (
                        <Alerts check title='Zapłacone!'>
                          {order.paidAt}
                        </Alerts>
                      ) : (
                        <Alerts attention title='Nie zapłacone!' />
                      )}
                    </dd>
                  </div>
                  <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Zamówione produkty:
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {order.orderItems.length === 0 ? (
                        <Alerts attention title='Uwaga!'>
                          Zamówienie jest puste
                        </Alerts>
                      ) : (
                        <ul className=''>
                          {order.orderItems.map((item, index) => (
                            <li key={index}>
                              <Link to={`/produkt/${item.product}`}>
                                {item.name}
                              </Link>
                              <span>
                                {' '}
                                x {item.qty} ={' '}
                                {item.qty * (item.price - item.discount)} zł
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className='w-full lg:w-2/5'>
              <div className='p-6 flex flex-col text-center gap-6 bg-white rounded-lg shadow lg:sticky top-2.5'>
                <h2 className='text-xl font-semibold text-gray-900'>
                  Podsumowanie
                </h2>
                <div>
                  <dl className='mt-2 border-t border-b border-gray-200 divide-y divide-gray-200'>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>
                        Cena wszystkich produktów
                      </dt>
                      <dd className='text-gray-900'>{order.itemsPrice} zł</dd>
                    </div>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>Koszt wysyłki</dt>
                      <dd className='text-gray-900'>
                        {order.shippingPrice} zł
                      </dd>
                    </div>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>Podatek 23%</dt>
                      <dd className='text-gray-900'>
                        {addDecimals(order.taxPrice)} zł
                      </dd>
                    </div>
                    <div className='py-3 flex justify-between text-sm font-medium'>
                      <dt className='text-gray-600'>Łącznie</dt>
                      <dd className='text-lg text-gray-900'>
                        {addDecimals(order.totalPrice)} zł
                      </dd>
                    </div>
                  </dl>
                </div>
                <h2 className='text-xl font-semibold text-gray-900'>
                  Płatność
                </h2>
                {order.paymentMethod === 'Przelew na konto' ? (
                  <p className='text-sm font-medium text-gray-600'>
                    Prosimy o wpłatę bezpośrednio na nasze konto bankowe:{' '}
                    <span className='text-gray-900'>
                      83 2530 0008 2090 1058 9327 0001
                    </span>{' '}
                    Nest Bank. Proszę użyć ID zamówienia jako tytułu płatności.
                    Twoje zamówienie zostanie zrealizowane po zaksięgowaniu
                    wpłaty na naszym koncie.
                  </p>
                ) : (
                  <p className='text-sm font-medium text-gray-600'>
                    Płatność za pobraniem
                  </p>
                )}
                {loadingDeliver && <Loader />}
                {userInfo &&
                  userInfo.isAdmin &&
                  order.isPaid &&
                  !order.isShipped && (
                    <button
                      onClick={deliverHandler}
                      className='flex-1 bg-imago_yellow py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                    >
                      Oznacz jako wysłane
                    </button>
                  )}
                {userInfo && userInfo.isAdmin && !order.isPaid && (
                  <button
                    onClick={successPaymentHandler}
                    className='flex-1 bg-imago_yellow py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                  >
                    Oznacz jako opłacone
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default OrderScreen
